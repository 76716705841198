export default function XucThirdPartyLoginUrl($log, $rootScope, $http, $window, remoteConfiguration, XucLink) {
  let url;
  
  var uriParamsOnLogin = function () {
    let login = XucLink.getXucUser().username;
    let token = XucLink.getXucUser().token;
    let xivocchost = $window.externalConfig.host;

    let url2 = url.replace(new RegExp('%{login}', 'g'), login)
      .replace(new RegExp('%{token}', 'g'), token)
      .replace(new RegExp('%{xivocchost}', 'g'), xivocchost);
    return url2;
  };

  var _init = function() {
    $log.info("Starting XucThirdPartyLoginUrl service");
    remoteConfiguration.get("thirdPartyWsLoginUrl").then((value) => {
      if (value && value.length > 0){
        url = value;
        $rootScope.$on('ctiLoggedOn', _loggedOnCallback);
      }
    });    
  };

  var _loggedOnCallback = function () {
    let urlReplaced = uriParamsOnLogin();

    $http.get(urlReplaced)
      .then(function success() {
        $log.debug("login third party handler success");
      }, function error(e) {
        $log.error("login third party handler error in url" + ' ' + urlReplaced, e);
      });
  };

  _init();

  return {
    init: _init
  };
  
}
