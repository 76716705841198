export default function XucFailedDestination($http, $q, XucLink) {
  const timeout = 5000;

  var _getToken = (func) => {
    return XucLink.whenLogged().then((user) => {
      let headers = {headers: {'Authorization': 'Bearer ' + user.token}, timeout: timeout};
      return func(headers);
    });
  };

  var _getUrl = (id) => {
    return XucLink.getServerUrl('http') + `/xuc/api/2.0/config/queue/${id}/dissuasion`;
  };

  var _getDissuasionSelected = (idQueue) => {
    let apiCall = (headers) => {
      return $http.get(_getUrl(idQueue), headers);
    };

    return _getToken(apiCall);
  };

  var _getAvailableDissuasions = (idQueue) => {
    let apiCall = (headers) => {
      return $http.get(_getUrl(idQueue) + "s", headers);
    };

    return _getToken(apiCall);
  };

  var _getListDissuasion = (idQueue, queueName) => {
    let deferred = $q.defer();

    $q.all([
      _getAvailableDissuasions(idQueue),
      _getDissuasionSelected(idQueue)
    ]).then((result) => {
      let returnedList = {
        soundFiles: [],
        queues: []
      };
      let listSoundFile = result[0].data;
      let dissuasionSelected = result[1].data;

      for (let sound_file of listSoundFile.dissuasions.soundFiles) {
        let elem = {
          fileName : sound_file.soundFile,
          displayName : sound_file.soundFile.substring(queueName.length +1),
          selected : false
        };

        returnedList.soundFiles.push(elem);
      }

      for (let queue of listSoundFile.dissuasions.queues) {
        let elem = {
          name : queue.queueName,
          selected : false
        };

        returnedList.queues.push(elem);
      }
      if (dissuasionSelected.dissuasion.type === "soundFile") {
        let index = returnedList.soundFiles
          .findIndex(soundFile => soundFile.fileName === dissuasionSelected.dissuasion.value.soundFile);
        if (index !== -1) {
          returnedList.soundFiles[index].selected = true;
        }
      } else if (dissuasionSelected.dissuasion.type === "queue") {
        let index = returnedList.queues
          .findIndex(queue => queue.name === dissuasionSelected.dissuasion.value.queueName);
        if (index !== -1) {
          returnedList.queues[index].selected = true;
        }
      }

      deferred.resolve(returnedList);
    }, (err) => {
      deferred.reject(err);
    });

    return deferred.promise;
  };

  var _setSoundFileDissuasion = (idQueue, fileName) => {
    let data = {
      "soundFile": fileName
    };
    let apiCall = (headers) => {
      return $http.put(_getUrl(idQueue) + "/sound_file", data, headers);
    };

    return _getToken(apiCall);
  };

  var _setQueueDestination = (idQueue, queueName) => {
    let data = {
      "queueName": queueName
    };
    let apiCall = (headers) => {
      return $http.put(_getUrl(idQueue) + "/queue", data, headers);
    };
    
    return _getToken(apiCall);
  };

  return {
    getDissuasionSelected: _getDissuasionSelected,
    getAvailableDissuasions: _getAvailableDissuasions,
    getListDissuasion: _getListDissuasion,
    setSoundFileDissuasion: _setSoundFileDissuasion,
    setQueueDestination: _setQueueDestination
  };
}