import _ from 'lodash';

export default class ViewController {

  constructor($scope, $state, $rootScope, XucCallHistory, XucPhoneEventListener, $transitions, CtiProxy,
    XucUtils, callContext, $stateParams, externalView, applicationConfiguration, XucPhoneState, $translate, UserPreferenceService) {
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.XucCallHistory = XucCallHistory;
    this.XucPhoneEventListener = XucPhoneEventListener;
    this.XucPhoneState = XucPhoneState;
    this.XucUtils = XucUtils;
    this.CtiProxy = CtiProxy;
    this.$transitions = $transitions;
    this.callHistory = [];
    this.callContext = callContext;
    this.$translate = $translate;
    this.applicationConfiguration = applicationConfiguration;
    this.isPhone = true;
    this.missedCalls = 0;
    this.showExternalViewButton = externalView.isURLSet();
    this.appConfig = applicationConfiguration.getCurrentAppConfig();
    this.tooltipVisible = false;
    this.tooltip = undefined;
    this.UserPreferenceService = UserPreferenceService;

    this.$scope.$on(UserPreferenceService.NEW_NB_MISSED_CALLS, (e, missedCall) => {
      this.updateMissedCalls(missedCall);
    });

    $scope.isActive = (viewName) => {
      return this.$state.is(viewName);
    };
    this.init();
  }

  showExternalView() {
    this.$state.go('interface.externalview');
  }

  updateMissedCalls(nbMissedCall) {
    this.missedCalls = nbMissedCall;
  }

  callHistoryUpdated(_callHistory) {
    this.callHistory = _callHistory;
  }

  historyChecked() {
    this.UserPreferenceService.resetMissedCalls();
  }

  getUserInput() {
    return this.XucUtils.reshapeInput(this.$scope.destination);
  }

  setInputValid() {
    this.tooltipVisible = false;
  }

  setInputInvalid(tooltip = 'UC_INVALID_SEARCH') {
    this.tooltip = tooltip;
    this.tooltipVisible = true;
  }

  dialOrSearch() {
    const input = this.getUserInput();
    this.setInputValid();
    if (input.type === 'phone') {
      this.dial();
    } else {
      this.search();
    }
  }

  dial() {
    const input = this.getUserInput();
    return this.callContext.dialOrAttTrans(input.value);
  }

  search() {
    const input = this.getUserInput();
    let inputValue = input.value;
    if (inputValue != undefined && inputValue.length >= 2 ) {
      this.$state.go('interface.search', { 'showFavorites': false, 'search': input.value });
      this.setInputValid();
    } else {
      this.setInputInvalid();
    }
  }

  isEnterKey(event) {
    if (event.which == 13) {
      angular.element('#search').blur();
      event.preventDefault();
      this.dialOrSearch();
    } else {
      this.changeIcon();
    }
  }
  
  changeIcon() {
    let normalizedValue = this.XucUtils.normalizePhoneNb(this.$scope.destination);
    this.$scope.isPhone = this.XucUtils.isaPhoneNb(normalizedValue);
  }

  clickSearch() {
    this.dialOrSearch();
  }

  emptyInput() {
    this.$scope.destination = "";
    this.changeIcon();
  }

  getConference() {
    let c = _.find(this.calls, (call) => {
      return call.conference != undefined;
    });

    if(c) {
      return c.conference;
    }
  }

  isConference() {
    return this.XucPhoneState.getConference();
  }

  canInviteToConference() {
    let canInvite = this.XucPhoneState.getConferenceOrganizer();
    if (!canInvite) {
      return 'blockedInvite';
    }
  }

  inviteToConference() {
    const input = this.getUserInput();
    let conference = this.XucPhoneState.getConference();
    if (conference != undefined) {
      this.CtiProxy.conferenceInvite(conference.conferenceNumber, input.value, 'User', true);
    }
  }

  navigateTo(place) {
    switch(place) {
    case 'favorites':
      this.$state.go('interface.favorites');
      break;
    case 'history':
      this.$state.go('interface.history');
      break;
    case 'conversationHistory':
      this.$state.go('interface.conversationHistory');
      break;
    }
  }

  init() {
    this.updateMissedCalls(this.UserPreferenceService.getMissedCalls());
    this.$transitions.onSuccess({}, trans => {
      if (trans.$to().name == 'interface.history') {
        this.XucCallHistory.subscribeToUserCallHistory(this.$scope, this.callHistoryUpdated.bind(this));
        this.XucCallHistory.updateUserCallHistory();   
        this.historyChecked();
      } else {
        this.XucCallHistory.unSubscribeToUserCallHistory();
      }
    });
  }

}
