export default function mediaDevices($window, $q) {

  var deferred;
  
  function _getAudioOutput() {
    deferred = $q.defer();
    $window.navigator.mediaDevices.enumerateDevices()
      .then(result => {
        deferred.resolve(result.filter(item => {
          return item.kind === 'audiooutput';
        }));
      });
    return deferred.promise;
  }
  
  return {
    getAudioOutput: _getAudioOutput
  };
}
