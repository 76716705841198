export default function callLister(){
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/callLister.html',
    scope: {
      callList: '=',
      iconRetrieved: '=iconRetrieved'
    }
  };
}
