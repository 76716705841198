import _ from 'lodash';

export default class forward {
  constructor($translate, XucUtils){
    this.$translate = $translate;
    this.XucUtils = XucUtils;

    this.dndTypes = [
      {id: 'dnd', icon: 'forward_dnd', enable: 'dndEnabled'}
    ];

    this.forwardTypes = [
      {id: 'uncFwd', label: this.$translate.instant('FORWARD_TYPE_UNC'), icon: 'forward', enable: 'uncFwdEnabled', destination:'uncFwdDestination'},
      {id: 'naFwd', label: this.$translate.instant('FORWARD_TYPE_NA'), icon: 'forward_na', enable: 'naFwdEnabled', destination:'naFwdDestination'}
    ];
  }

  getIcon (user) {
    let fwd = this.getType(user);
    return (fwd) ? fwd.icon : null;
  }

  getDestination (user) {
    this.validateExtensions(user);
    if (user.dndEnabled) return this.$translate.instant('FORWARD_DND');
    if (user.uncFwdEnabled) return user.uncFwdDestination;
    if (user.naFwdEnabled) return user.naFwdDestination;
    if (user.busyFwdEnabled) return user.busyFwdDestination;
  }

  isForwarded (user) {
    return user.naFwdEnabled || user.uncFwdEnabled || user.busyFwdEnabled;
  }

  isSet (user) {
    return this.isForwarded(user) || user.dndEnabled;
  }

  setForward (user) {
    user.naFwdDestination = this.XucUtils.normalizePhoneNb(user.naFwdDestination);
    user.uncFwdDestination = this.XucUtils.normalizePhoneNb(user.uncFwdDestination);
    this.validateExtensions(user);
    Cti.naFwd(user.naFwdDestination, user.naFwdEnabled);
    Cti.uncFwd(user.uncFwdDestination, user.uncFwdEnabled);
  }

  setNaFwd (user) {
    user.naFwdDestination = this.XucUtils.normalizePhoneNb(user.naFwdDestination);
    this.validateNaExtensions(user);
    Cti.naFwd(user.naFwdDestination, user.naFwdEnabled);
  }

  setUncFwd (user) {
    user.uncFwdDestination = this.XucUtils.normalizePhoneNb(user.uncFwdDestination);
    this.validateUncExtensions(user);
    Cti.uncFwd(user.uncFwdDestination, user.uncFwdEnabled);
  }

  setDnd (user) {
    Cti.dnd(!user.dndEnabled);
  }

  getType (user) {
    let type;

    if (user.naFwdEnabled) {
      type = _.find(this.forwardTypes, { 'id': 'naFwd'});
    }
    if (user.uncFwdEnabled) {
      type = _.find(this.forwardTypes, { 'id': 'uncFwd'});
    }
    if (user.dndEnabled) {
      type = _.find(this.dndTypes, { 'id': 'dnd'});
    }

    return type;
  }

  validateUncExtensions (user) {
    if (typeof(user.uncFwdDestination) === "undefined" || !this.XucUtils.isaPhoneNb(user.uncFwdDestination)) {
      user.uncFwdDestination = "";
    }
  }

  validateNaExtensions (user) {
    if (typeof(user.naFwdDestination) === "undefined" || !this.XucUtils.isaPhoneNb(user.naFwdDestination)) {
      user.naFwdDestination = "";
    }
  }

  validateExtensions (user) {
    this.validateUncExtensions(user);
    this.validateNaExtensions(user);
  }
}