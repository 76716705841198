export default function personalContact($http, $log, $window, $q, XucLink, fileReader, remoteConfiguration){
  const timeout = 5000;
  var maximumAllowedFileSize = 20000000;

  remoteConfiguration.getInt("maxImportFileSize").then((value) => {
    maximumAllowedFileSize = value;
  });

  var _getToken = (func) => {
    return XucLink.whenLogged().then((user) => {
      let headers = {headers: { 'Authorization': 'Bearer ' + user.token }, timeout: timeout};
      return func(headers);
    });
  };

  var _getUrl = (id) => {
    let param = id ? '/'+id : '';
    return XucLink.getServerUrl('http')+"/xuc/api/2.0/contact/personal"+param;
  };

  var _list = () => {
    let apiCall = (headers) => {
      return $http.get(_getUrl(), headers);
    };

    return _getToken(apiCall);
  };

  var _add = (pc) => {
    let apiCall = (headers) => {
      return $http.post(_getUrl(), pc, headers);
    };

    return _getToken(apiCall);
  };

  var _get = (pcId) => {
    let apiCall = (headers) => {
      return $http.get(_getUrl(pcId), headers);
    };

    return _getToken(apiCall);
  };

  var _update = (pc, pcId) => {
    let apiCall = (headers) => {
      return $http.put(_getUrl(pcId), pc, headers);
    };

    return _getToken(apiCall);
  };

  var _remove = (pcId) => {
    let apiCall = (headers) => {
      return $http.delete(_getUrl(pcId), headers);
    };

    return _getToken(apiCall);
  };

  var _removeAll = () => {
    let apiCall = (headers) => {
      return $http.delete(_getUrl(), headers);
    };

    return _getToken(apiCall);
  };

  var _export = () => {
    XucLink.whenLogged().then((user) => {
      let url = XucLink.getServerUrl('http')+"/xuc/api/2.0/contact/export/personal?token="+user.token;
      $window.open(url);
    });
  };

  var _import = (file, scope) => {
    const pattern = /.+(\.csv)$/i;
    if (!(file.name).match(pattern)) {
      return $q.reject({data:{error:'InvalidFileType'}});
    }
    if (file.size > maximumAllowedFileSize) {
      return $q.reject({data:{error:'FileTooLarge'}});
    }
    let apiCall = (headers) => {
      headers.headers['Content-Type'] = 'text/plain;charset=UTF-8';
      let url = XucLink.getServerUrl('http')+"/xuc/api/2.0/contact/import/personal";
      return fileReader.readAsText(file, scope).then((data) => {
        return $http.post(url, data, headers);
      });
    };

    return _getToken(apiCall);
  };

  return {
    list : _list,
    add : _add,
    get : _get,
    update : _update,
    remove: _remove,
    removeAll: _removeAll,
    export: _export,
    import: _import
  };
}