import _ from 'lodash';

export default function externalView() {
  var _externalViewUrl;

  const _setURL = (url)  => {
    _externalViewUrl = url;
  };

  const _getURL = ()  => {
    return _externalViewUrl;
  };

  const _isURLSet = () => {
    return !_.isEmpty(_getURL());
  };

  return {
    setURL: _setURL,
    getURL: _getURL,
    isURLSet: _isURLSet
  };
}
