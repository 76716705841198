export default function volumeBar() {
  
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/volumeBar.html',
    scope: {
      volume: '=',
      progressBarClasses: '&?',
      iconClasses: '&?'
    }
  };
}
