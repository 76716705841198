"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XucAuthErrorCode = void 0;
var XucAuthErrorCode;
(function (XucAuthErrorCode) {
    XucAuthErrorCode["NoStoredCredentials"] = "NoStoredCredentials";
    XucAuthErrorCode["LinkClosed"] = "LinkClosed";
    XucAuthErrorCode["NoResponse"] = "NoResponse";
    XucAuthErrorCode["ThirdPartyNoResponse"] = "3rdPartyNoResponse";
    XucAuthErrorCode["LoggedInOnAnotherPhone"] = "LoggedInOnAnotherPhone";
    XucAuthErrorCode["EmptyLogin"] = "EmptyLogin";
    XucAuthErrorCode["UserNotFound"] = "UserNotFound";
    XucAuthErrorCode["InvalidCredentials"] = "InvalidCredentials";
    XucAuthErrorCode["InvalidToken"] = "InvalidToken";
    XucAuthErrorCode["InvalidJson"] = "InvalidJson";
    XucAuthErrorCode["BearerNotFound"] = "BearerNotFound";
    XucAuthErrorCode["AuthorizationHeaderNotFound"] = "AuthorizationHeaderNotFound";
    XucAuthErrorCode["TokenExpired"] = "TokenExpired";
    XucAuthErrorCode["UnhandledError"] = "UnhandledError";
    XucAuthErrorCode["SsoAuthenticationFailed"] = "SsoAuthenticationFailed";
    XucAuthErrorCode["CasServerUrlNotSet"] = "CasServerUrlNotSet";
    XucAuthErrorCode["CasServerInvalidResponse"] = "CasServerInvalidResponse";
    XucAuthErrorCode["CasServerInvalidParameter"] = "CasServerInvalidParameter";
    XucAuthErrorCode["CasServerInvalidRequest"] = "CasServerInvalidRequest";
    XucAuthErrorCode["CasServerInvalidTicketSpec"] = "CasServerInvalidTicketSpec";
    XucAuthErrorCode["CasServerUnauthorizedServiceProxy"] = "CasServerUnauthorizedServiceProxy";
    XucAuthErrorCode["CasServerInvalidProxyCallback"] = "CasServerInvalidProxyCallback";
    XucAuthErrorCode["CasServerInvalidTicket"] = "CasServerInvalidTicket";
    XucAuthErrorCode["CasServerInvalidService"] = "CasServerInvalidService";
    XucAuthErrorCode["CasServerInternalError"] = "CasServerInternalError";
    XucAuthErrorCode["OidcNotEnabled"] = "OidcNotEnabled";
    XucAuthErrorCode["OidcInvalidParameter"] = "OidcInvalidParameter";
    XucAuthErrorCode["OidcAuthenticationFailed"] = "OidcAuthenticationFailed";
    XucAuthErrorCode["InvalidConfiguration"] = "InvalidConfiguration";
    XucAuthErrorCode["Logout"] = "Logout";
    XucAuthErrorCode["RequirePhoneNumber"] = "RequirePhoneNumber";
    XucAuthErrorCode["NotAnAgent"] = "NotAnAgent";
})(XucAuthErrorCode = exports.XucAuthErrorCode || (exports.XucAuthErrorCode = {}));
