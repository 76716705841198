export default function scrollText($log, $animateCss) {

  return {
    restrict: 'E',
    transclude: true,
    template: '<div class="slide-text"><div ng-transclude></div></div>',
    scope: { },
    link: (scope, element) => {
      scope.scrollForward = false;
      scope.scrollAmount = 0;
      
      var container = element.find('div.slide-text');
      var text = element.find('div.slide-text div');

      var animate = () => {

        var from, to;
        
        if(scope.scrollForward) {
          from = -10 - scope.scrollAmount + 'px';
          to = 5;
        } else {
          from = 5;
          to = -10 - scope.scrollAmount + 'px';
        }
        scope.scrollForward = !scope.scrollForward;
        
        var options = {
          from: {
            'text-indent': from
          },
          to: {
            'text-indent': to
          },
          easing: 'ease-in-out',
          duration: '3'
        };

        var animation = $animateCss(text, options);
        animation.start().then(animate);
      };

      angular.element(document).ready(() => {
        scope.scrollAmount = container[0].scrollWidth - container[0].clientWidth;
        if(container[0].clientWidth > 110) {
          container.css('overflow', 'hidden');
          animate();
        }
      });
    }
  };
}
