/*
Upload packets lost (client to server) -> 
- First we get the current report packet lost amount by substracting the current total of packet lost since the start with the previous report 
total of packet lost since the start.
- Then we get the current report packet sent amount the same way, by substracting the previous report total of packet sent since the start with the
current total packet sent since the start.
- We divide the packet lost with the packet received and end up with the packet lost ratio, and then multiply it by 100 to get a percentage.

With numbers ->
- considering 110 packets lost in total for the current report, and 100 for the previous one (accumulator)
- considering 2200 packets sent in total for the current report, and 2000 for the previous one (accumulator)

This would be 10 packets lost in the last 200 packets sent, resulting in the following numbers

(110 - 100) / (2200 - 2000) * 100
10 / 200 * 100
0.05 * 100
5%
*/

class UploadAudioQualityReport {
  constructor(RTCOutboundRTPAudioStream, RTCRemoteInboundRtpAudioStream, currentUploadAudioQualityReport = {packetsTotalAccumulator: 0, packetsLostAccumulator: 0}) {
    this.jitter = Math.round(RTCRemoteInboundRtpAudioStream.jitter * 1000);
    this.packetsLostPercentage = Math.round(
      (RTCRemoteInboundRtpAudioStream.packetsLost - currentUploadAudioQualityReport.packetsLostAccumulator) / 
      Math.abs((RTCOutboundRTPAudioStream.packetsSent - currentUploadAudioQualityReport.packetsTotalAccumulator)) * 100
    );
    this.roundTripTime = Math.round(RTCRemoteInboundRtpAudioStream.roundTripTime * 1000);
    this.packetTotal = `${RTCRemoteInboundRtpAudioStream.packetsLost}/${RTCOutboundRTPAudioStream.packetsSent - RTCRemoteInboundRtpAudioStream.packetsLost}`;

    this.packetsTotalAccumulator = RTCOutboundRTPAudioStream.packetsSent;
    this.packetsLostAccumulator = RTCRemoteInboundRtpAudioStream.packetsLost;
  }
}

/*
Download packets lost (server to client) -> 
- First we get the current report packet lost amount by substracting the current total of packet lost since the start with the previous report 
total of packet lost since the start.
- Then we combine the total of packets received and packet losts from the remote to get the sum representing the total amount of packets, and substract the total
that we found during the previous report.
- We divide the packet lost with the packet received and end up with the packet lost ratio, and then multiply it by 100 to get a percentage.

With numbers ->
- considering 150 packets lost in total for the current report, and 100 for the previous one (accumulator)
- considering 2100 packets received and 150 packets lost for the current report, and 2000 total packets found during the previous one (accumulator)

This would be 50 packets lost in the last 300 packets sent, resulting in the following numbers

(150 - 100) / ((2100 + 150) - 2000) * 100
50 / 250 * 100
0.2 * 100
20%
*/

class DownloadAudioQualityReport {
  constructor(RTCInboundRTPAudioStream, currentDownloadAudioQualityReport = {packetsTotalAccumulator: 0, packetsLostAccumulator: 0}) {
    this.jitter = Math.round(RTCInboundRTPAudioStream.jitter * 1000);
    this.packetsLostPercentage = Math.round(
      (RTCInboundRTPAudioStream.packetsLost - currentDownloadAudioQualityReport.packetsLostAccumulator) / 
      Math.abs((RTCInboundRTPAudioStream.packetsReceived + RTCInboundRTPAudioStream.packetsLost - currentDownloadAudioQualityReport.packetsTotalAccumulator)) * 100
    );
    this.packetTotal = `${RTCInboundRTPAudioStream.packetsLost}/${RTCInboundRTPAudioStream.packetsReceived}`;
    this.packetsTotalAccumulator = RTCInboundRTPAudioStream.packetsReceived + RTCInboundRTPAudioStream.packetsLost;
    this.packetsLostAccumulator = RTCInboundRTPAudioStream.packetsLost;
  }
}

class MonitoredCall {
  constructor(sipCallId, audioConnection) {
    this.sipCallId = sipCallId;
    this.audioConnection = audioConnection;
    this.audioQualityInterval = undefined;
    this.currentUploadAudioQualityReport = undefined;
    this.currentDownloadAudioQualityReport = undefined;

    this.spikeUpload = {
      jitter: 0,
      packetsLostPercentage: 0,
      roundTripTime: 0
    };

    this.spikeDownload = {
      jitter: 0,
      packetsLostPercentage: 0,
    };
  }

  getHighest(a, b) {
    if (a > b) return a;
    else return b;
  }

  adjustSpikeValues() {
    this.spikeUpload.jitter = this.getHighest(this.spikeUpload.jitter, this.currentUploadAudioQualityReport.jitter);
    this.spikeUpload.packetsLostPercentage = this.getHighest(this.spikeUpload.packetsLostPercentage, noNan(this.currentUploadAudioQualityReport.packetsLostPercentage));
    this.spikeUpload.roundTripTime = this.getHighest(this.spikeUpload.roundTripTime, this.currentUploadAudioQualityReport.roundTripTime);
    this.spikeDownload.jitter = this.getHighest(this.spikeDownload.jitter, this.currentDownloadAudioQualityReport.jitter);
    this.spikeDownload.packetsLostPercentage = this.getHighest(this.spikeDownload.packetsLostPercentage, noNan(this.currentDownloadAudioQualityReport.packetsLostPercentage));
  }
}

const noNan = (number) => {
  return isNaN(number) ? 100 : number;
};

export default function audioQualityStatistics($rootScope, $interval, $log, $timeout) {

  const AUDIO_QUALITY_EVENT = 'audioQualityEvent';
  const AUDIO_QUALITY_GOOD = 'goodAudioQuality';
  const AUDIO_QUALITY_MEDIUM = 'mediumAudioQuality';
  const AUDIO_QUALITY_BAD = 'badAudioQuality';
  const AUDIO_QUALITY_MONITORING_MS = 2000;
  const AUDIO_QUALITY_LOG_PROPAGATION_MS = 20000;

  const AudioQualityStandards = {
    bad: {
      jitter: 100,
      packetsLostPercentage: 20,
      roundTripTime: 300
    },
    medium: {
      jitter: 50,
      packetsLostPercentage: 10,
      roundTripTime: 150
    }
  };

  let monitoredCalls = [];
  let logPropagationLock = false;

  const startAudioQualityMonitoring = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    
    if (monitoredCall) {
      initAudioQualityInterval(monitoredCall);
    }
    else {
      let newMonitoredCall = createCallObject(sipCallId);
      monitoredCalls.push(newMonitoredCall);
    }
  };

  const createCallObject = (sipCallId) => {
    let monitoredCall = new MonitoredCall(sipCallId, xc_webrtc.getCurrentRTCPeerConnection(sipCallId));
    initAudioQualityInterval(monitoredCall);
    return monitoredCall;
  };

  const initAudioQualityInterval = (monitoredCall) => {
    if (!monitoredCall.audioQualityInterval) monitoredCall.audioQualityInterval = $interval(getStatistics.bind(null, monitoredCall.sipCallId), AUDIO_QUALITY_MONITORING_MS);
  };
  
  const stopAudioQualityMonitoring = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    if (monitoredCall) {
      $log.info(generateCallReport(monitoredCall), undefined, true);
      $interval.cancel(monitoredCall.audioQualityInterval);
      monitoredCalls.splice(monitoredCalls.findIndex(_ => _.sipCallId == sipCallId), 1);
    }
  };
  
  const getStatistics = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    monitoredCall.audioConnection.getStats(null).then(processAudioStatistics.bind(null, sipCallId), err => $log.error(err));
  };
  
  const processAudioStatistics = (sipCallId, data) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    let outboundAudioStream;
    let remoteInboundAudioStream;
    let inboundAudioStream;
    data.forEach(report => {
      if ((report.id.includes('RTCOutboundRTPAudioStream') || report.type == 'outbound-rtp') && report.kind == 'audio') outboundAudioStream = report;
      if ((report.id.includes('RTCRemoteInboundRtpAudioStream') || report.type == 'remote-inbound-rtp') && report.kind == 'audio') remoteInboundAudioStream = report;
      if ((report.id.includes('RTCInboundRTPAudioStream') ||  report.type == 'inbound-rtp') && report.kind == 'audio') inboundAudioStream = report;
    });
    if (outboundAudioStream && remoteInboundAudioStream && inboundAudioStream && monitoredCall) {
      monitoredCall.currentUploadAudioQualityReport = new UploadAudioQualityReport(outboundAudioStream, remoteInboundAudioStream, monitoredCall.currentUploadAudioQualityReport);
      monitoredCall.currentDownloadAudioQualityReport = new DownloadAudioQualityReport(inboundAudioStream, monitoredCall.currentDownloadAudioQualityReport);
      monitoredCall.adjustSpikeValues();
      processAudioQuality(monitoredCall);
    } 
  };
  
  const getCallBySipCallId = (sipCallId) => {
    return monitoredCalls.find(_ => _.sipCallId == sipCallId);
  };
  
  const processAudioQuality = (call) => {
    let currentCallQuality = getAudioQuality(call);
    if (currentCallQuality == AUDIO_QUALITY_BAD || currentCallQuality == AUDIO_QUALITY_MEDIUM && !logPropagationLock) {
      $log.error(generateAudioStatsReport(call), undefined, true);
      logPropagationLock = true;
      $timeout(() => { logPropagationLock = false; }, AUDIO_QUALITY_LOG_PROPAGATION_MS);
    }

    call.currentUploadAudioQualityReport.packetsLostPercentage = noNan(call.currentUploadAudioQualityReport.packetsLostPercentage);
    call.currentDownloadAudioQualityReport.packetsLostPercentage = noNan(call.currentDownloadAudioQualityReport.packetsLostPercentage);

    $rootScope.$broadcast(AUDIO_QUALITY_EVENT,{
      quality: currentCallQuality,
      statistics: {uploadStatistics: call.currentUploadAudioQualityReport, downloadStatistics: call.currentDownloadAudioQualityReport}      
    });
  };

  const generateAudioStatsReport = (monitoredCall) => {
    return `${monitoredCall.sipCallId} Audio quality issues -> ` +
      ` RTT ${monitoredCall.currentUploadAudioQualityReport.roundTripTime}ms ` +
      `- Jitter upstream ${monitoredCall.currentUploadAudioQualityReport.jitter}ms / downstream ${monitoredCall.currentDownloadAudioQualityReport.jitter}ms ` +
      `- Packet loss upstream ${noNan(monitoredCall.currentUploadAudioQualityReport.packetsLostPercentage)}% / downstream ${noNan(monitoredCall.currentDownloadAudioQualityReport.packetsLostPercentage)}% `;
  };

  const generateCallReport = (monitoredCall) => {
    return `${monitoredCall.sipCallId} Call quality report ->` +
    ` Highest RTT ${monitoredCall.spikeUpload.roundTripTime}ms ` +
    `- Highest Jitter upstream ${monitoredCall.spikeUpload.jitter}ms / downstream ${monitoredCall.spikeDownload.jitter}ms ` +
    `- Highest Packet loss upstream ${monitoredCall.spikeUpload.packetsLostPercentage}% / downstream ${monitoredCall.spikeDownload.packetsLostPercentage}% `;
  };

  const getAudioQuality = (call) => {
    let downloadAudioQuality = getDownloadQuality(call.currentDownloadAudioQualityReport);
    let uploadAudioQuality = getUploadQuality(call.currentUploadAudioQualityReport);

    if (downloadAudioQuality == AUDIO_QUALITY_BAD || uploadAudioQuality == AUDIO_QUALITY_BAD) return AUDIO_QUALITY_BAD;
    if (downloadAudioQuality == AUDIO_QUALITY_MEDIUM || uploadAudioQuality == AUDIO_QUALITY_MEDIUM) return AUDIO_QUALITY_MEDIUM;
    if (downloadAudioQuality == AUDIO_QUALITY_GOOD || uploadAudioQuality == AUDIO_QUALITY_GOOD) return AUDIO_QUALITY_GOOD;
    
  };

  const getDownloadQuality = (downloadReport) => {
    let badStandard = AudioQualityStandards.bad;
    if ( downloadReport.jitter > badStandard.jitter
      || downloadReport.packetsLostPercentage > badStandard.packetsLostPercentage
    ) return AUDIO_QUALITY_BAD;
    
    let mediumStandard = AudioQualityStandards.medium;
    if ( downloadReport.jitter > mediumStandard.jitter
      || downloadReport.packetsLostPercentage > mediumStandard.packetsLostPercentage
    ) return AUDIO_QUALITY_MEDIUM;

    return AUDIO_QUALITY_GOOD;
  };

  const getUploadQuality = (uploadReport) => {

    let badStandard = AudioQualityStandards.bad;
    if ( uploadReport.jitter > badStandard.jitter
      || uploadReport.roundTripTime > badStandard.roundTripTime
      || uploadReport.packetsLostPercentage > badStandard.packetsLostPercentage
    ) return AUDIO_QUALITY_BAD;
    
    let mediumStandard = AudioQualityStandards.medium;
    if ( uploadReport.jitter > mediumStandard.jitter
      || uploadReport.roundTripTime > mediumStandard.roundTripTime
      || uploadReport.packetsLostPercentage > mediumStandard.packetsLostPercentage
    ) return AUDIO_QUALITY_MEDIUM;

    return AUDIO_QUALITY_GOOD;
  };

  return {
    startAudioQualityMonitoring: startAudioQualityMonitoring,
    stopAudioQualityMonitoring: stopAudioQualityMonitoring,
    AUDIO_QUALITY_EVENT: AUDIO_QUALITY_EVENT,
    AUDIO_QUALITY_BAD: AUDIO_QUALITY_BAD,
    AUDIO_QUALITY_MEDIUM: AUDIO_QUALITY_MEDIUM,
    AUDIO_QUALITY_GOOD: AUDIO_QUALITY_GOOD,
    AUDIO_QUALITY_MONITORING_MS: AUDIO_QUALITY_MONITORING_MS,
    AUDIO_QUALITY_LOG_PROPAGATION_MS: AUDIO_QUALITY_LOG_PROPAGATION_MS
  };
}
