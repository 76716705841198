export default function displayConversationHistory($rootScope, XucChat) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/displayConversationHistory.html',
    scope: {
      action: '='
    },
    link: (scope) => {
      load();

      function load() {
        scope.conversationHistory = XucChat.getConversationHistory();
        if (!$rootScope.$$phase) scope.$apply(() => {
          scope.conversationHistory;
        });
      }

      scope.$on(XucChat.CHAT_RECEIVED_MESSAGE, () => {
        load();
      });
    }
  };
}
