export default function dateInput(){
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/dateInput.html',
    scope: {
      date: "="
    },
    link: function(scope) {
      // embed in sub-object to allow date-picker to be open more than once
      // this is bug in the date-picker directive
      scope.calendar = { isOpened : false};
      scope.popupCalendar = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        scope.calendar.isOpened = !scope.calendar.isOpened;
      };
    }
  };
}
