"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Broadcaster {
    constructor($rootScope) {
        this.$rootScope = $rootScope;
        this.ongoingEvents = [];
        this.timeoutMs = 500;
        this.send = (event) => {
            if (!this.ongoingEvents.includes(event)) {
                this.ongoingEvents.push(event);
                this.startTimeout(event);
            }
        };
        this.startTimeout = (event) => {
            setTimeout(() => {
                this.$rootScope.$broadcast(event);
                this.ongoingEvents.splice(this.ongoingEvents.indexOf(event), 1);
            }, this.timeoutMs);
        };
    }
}
exports.default = Broadcaster;
Broadcaster.$inject = ['$rootScope'];
