export default function fixedHeader($timeout, $window) {
  return {
    restrict: 'A',
    link: link
  };

  function link($scope, $elem, $attrs) {
    var WINDOW_HEIGHT_OFFSET = $attrs.heightOffset || 285;
    $elem.addClass('flex-layout');
    var elem = $elem[0];

    // wait for data to load and then transform the table
    $scope.$watch(tableDataLoaded, function(isTableDataLoaded) {
      if (isTableDataLoaded) {
        $scope.transformTable();
      }
    });

    function tableDataLoaded() {
      // first cell in the tbody exists when data is loaded
      var firstCell = elem.querySelector('table[fixed-header] > tbody > tr:first-child > td:first-child');
      return firstCell && !firstCell.style.width;
    }

    $scope.$watch(numRows, function (newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.transformTable();
      }
    });

    function numRows() {
      return elem.querySelectorAll('table[fixed-header] > tbody > tr').length;
    }

    function isValue(val) {
      return !(val === null || !angular.isDefined(val) || (angular.isNumber(val) && !isFinite(val)));
    }

    $scope.transformTable = function() {
      // wrap in $timeout to give table a chance to finish rendering
      $timeout(function () {

        // if not defined max-height attribute, compute viewport size available
        if (!isValue($attrs.maxTableHeight)) {
          var thead = elem.querySelector('table[fixed-header] > thead');
          $attrs.maxTableHeight = (document.documentElement.clientHeight - WINDOW_HEIGHT_OFFSET - thead.offsetHeight) + 'px';
          if($attrs.maxTableHeight <= 0) $attrs.maxTableHeight = 'inherit';
        }

        var tbody = elem.querySelector('table[fixed-header] > tbody');
        angular.element(tbody).css({
          'height': $attrs.tableHeight || 'inherit',
          'max-height': $attrs.maxTableHeight
        });
      });
    };

    angular.element($window).bind('resize', resizeThrottler);
    var resizeTimeout;
    function resizeThrottler() {
      if (!resizeTimeout) {
        resizeTimeout = $timeout(function() {
          resizeTimeout = null;
          $attrs.maxTableHeight = null;
          $scope.transformTable();
        }, 100);
      }
    }
  }
}
