import d3 from 'd3';

export default function donutChart($translate) {
  return {
    restrict: 'E',
    scope: {
      data: "=",
      width: "@",
      height: "@"
    },
    template: '<nvd3 options="options" data="donutData"></nvd3>',
    link: (scope) => {

      scope.options = {
        chart: {
          type: 'pieChart',
          height: 360,
          donut: true,
          x: function(d){return d.key;},
          y: function(d){return d.y;},
          valueFormat: d3.format("d"),
          showLabels: false,
          color: ["#f89406","#3c3cbc","#a34719"], 
          duration: 500,
          pie: {
            margin: {
              bottom: 0,
              right: 0,
              top: 0,
            },
            padding: {
              top: 0,
            },
          
          },
          tooltip: {  
            classes: 'nvtooltip-zindex',
            keyFormatter: function(key){return $translate.instant(key) ;}  ,
            gravity: 's'
          },  
          legendPosition: 'bottom',
          legend: {
            margin: {
              bottom: 100,
            },
            padding: {
              left: 1,
            },
            maxKeyLength: 40,
            rightAlign: false,
            keyFormatter: function(key){return $translate.instant(key) ;}  
          }, 
        }
      };
      
      scope.fillData = function() {        
        scope.donutData = [];
        angular.forEach(scope.data, function(item) {
          var newItem = {};
          newItem.y = item.value;
          newItem.key = item.id;
          scope.donutData.push(newItem);
        });
      };

      scope.fillData();

      scope.$watchCollection('data', function(newValue, oldValue) {
        if (newValue == oldValue) return;
        scope.fillData();
      });      
    }
  };
}




