export default function headset(plantronics, CtiProxy, XucPhoneState, $rootScope, XucLink, $log) {
  'use strict';

  let _currentPhoneState;
  let _unregisterPlantronicsOnKeyPress;

  let init = () => {    
    _unregisterPlantronicsOnKeyPress = plantronics.addHeadphoneEventHandler(_onKeyPress);
    XucPhoneState.addStateHandler(_onPhoneState);
    XucLink.whenLoggedOut().then( () => {
      uninit();
    });
  };

  let uninit = () => {
    $log.info("Unloading Headset Service");
    _unregisterPlantronicsOnKeyPress();
    XucLink.whenLogged().then( () => {
      $log.info("Restarting Headset Service");
      init();
    });
  };

  let _onKeyPress = (keycode) => {
    if(keycode === 'Main') {
      switch(_currentPhoneState) {
      case XucPhoneState.STATE_RINGING:
        CtiProxy.answer();
        break;
      case XucPhoneState.STATE_ESTABLISHED:
        CtiProxy.hangup();
        break;
      case XucPhoneState.STATE_ONHOLD:
        CtiProxy.hold();
        break;      
      case XucPhoneState.STATE_DIALING:
        CtiProxy.hangup();
        break;
      }
    }
  };

  let _onPhoneState = (state) => {
    _currentPhoneState = state;
    if(state === XucPhoneState.STATE_AVAILABLE) {
      plantronics.stopPolling();
    } else {
      plantronics.startPolling();
    }
  };

  let getCurrentPhoneState = () => {
    return _currentPhoneState;
  };

  XucLink.whenLogged().then( () => {
    $log.info("Starting Headset Service");
    init();
  });

  return {
    init: init,
    uninit: uninit,
    onKeyPress: _onKeyPress,
    onPhoneState: _onPhoneState,
    getCurrentPhoneState: getCurrentPhoneState,
  };
}