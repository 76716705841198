export default function failedDestination(XucFailedDestination) {
  return {
    restrict: 'E',
    link : link,  
    templateUrl: 'assets/javascripts/xchelper/directives/failedDestination.html',
    scope: {
      activity: '=',
      name: '=?',
      title: '=?',
      dissuasion: '=?',
      opendissuasiondropdown: '&',
      closedissuasiondropdown: '&'
    }
  };
    
  function link ($scope){ 
    if (angular.isUndefined($scope.dissuasion)) {
      $scope.dissuasion = {};
    }
    
    $scope.getDissuasionList = function() { 
      $scope.emptyDissuasion = false;
      $scope.dissuasion_list = {queues: [], soundFiles: []};
      XucFailedDestination.getListDissuasion($scope.activity.id, $scope.activity.name).then((result) => {
        $scope.dissuasion_list = result;
        $scope.emptyDissuasion = this.isDissuasionListEmpty($scope.activity.name);
      });
      
    }; 

    $scope.dissuasionToggle = function(open) {
      if (open) {
        $scope.opendissuasiondropdown($scope.activity);
      } else {
        $scope.closedissuasiondropdown($scope.activity);
      }
    };

    $scope.getDissuasionClass = function (dissuasion) {
      if (dissuasion.selected) {
        return "dissuasion-selected";
      } else {
        return "dissuasion-not-selected";
      }
    };

    $scope.setDissuasion = function(idQueue, fileName) {
      XucFailedDestination.setSoundFileDissuasion(idQueue, fileName);
    };
  
    $scope.changeDissuasionToDefault = function(idQueue, queueName) {
      XucFailedDestination.setQueueDestination(idQueue, queueName);
    };
  
    $scope.displayDefaultQueue = function (activityName, queueName) {
      return activityName !== queueName;
    };
  
    $scope.isDissuasionListEmpty = function(activityName) {
      return $scope.dissuasion_list.soundFiles.length === 0 &&
        $scope.dissuasion_list.queues.filter(
          queue => activityName !== queue.name
        ).length === 0;
    }; 
  }
}