"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RingtoneSelectionController {
    constructor(_translate, _webRtcAudio, _xcHelperPreferences) {
        this._translate = _translate;
        this._webRtcAudio = _webRtcAudio;
        this._xcHelperPreferences = _xcHelperPreferences;
        this.ringtones = [
            {
                id: 1,
                name: this._translate.instant('DEFAULT_RINGTONE_NAME'),
                file: 'incoming_call.mp3',
                title: this._translate.instant('DEFAULT_RINGTONE_NAME'),
                author: ''
            },
            {
                id: 2,
                name: 'Alexander Nakarada - The Return',
                file: 'alexander_nakarada_the_return.mp3',
                title: 'The Return',
                author: 'Alexander Nakarada'
            },
            {
                id: 3,
                name: 'Keys of Moon - The Success',
                file: 'keys_of_moon_the_success.mp3',
                title: 'Keys of Moon - The Success',
                author: 'Alexander Nakarada'
            },
            {
                id: 4,
                name: 'Another Happy Ukulele Song',
                file: 'another_happy_ukulele_song.mp3',
                title: 'Another Happy Ukulele Song',
                author: ''
            },
            {
                id: 5,
                name: 'Cbakos - Cordless Phone Ring',
                file: 'cbakos_cordless_phone_ring.wav',
                title: 'Cordless Phone Ring',
                author: 'Cbakos'
            },
            {
                id: 6,
                name: 'Sandor Molnar - Fekete Rigo',
                file: 'sandor_molnar_fekete_rigo.mp3',
                title: 'Fekete Rigo',
                author: 'Sandor Molnar'
            },
            {
                id: 7,
                name: 'Imthemap - Pop Ringtone',
                file: 'imthemap_pop_ringtone.wav',
                title: 'Pop Ringtone',
                author: 'Imthemap'
            },
            {
                id: 8,
                name: 'Inchadney - British Telephone',
                file: 'inchadney_british_telephone.wav',
                title: 'British Telephone',
                author: 'Inchadney'
            },
        ];
        this.listeningRingtone = this.ringtones[0];
        this.ringtoneListener = null;
        this.playPromise = undefined;
        this.changeRingtone = (model) => {
            this.selectedRingtone = model;
            window.localStorage.setItem('user_ringtone', JSON.stringify(this.selectedRingtone));
            this._webRtcAudio.changeRingtone(model);
        };
        let user_ringtone = window.localStorage.getItem('user_ringtone');
        if (user_ringtone !== null) {
            this.selectedRingtone = JSON.parse(user_ringtone);
        }
        else {
            this.selectedRingtone = this.ringtones[0];
        }
        this.listeningRingtone = this.selectedRingtone;
    }
    listenToRingtone(ringtone, event) {
        event.stopPropagation();
        if (this.listeningRingtone !== null && ringtone.name !== this.listeningRingtone.name && this.playPromise !== undefined) {
            this.stopAudio(this.ringtoneListener);
        }
        if (this.playPromise === undefined) {
            this.listeningRingtone = ringtone;
            this.startAudio(this.ringtoneListener);
        }
        else {
            this.stopAudio(this.ringtoneListener);
        }
    }
    stopAudio(element) {
        element.pause();
        element.currentTime = 0;
        this.playPromise = undefined;
    }
    ;
    startAudio(element) {
        element.src = 'assets/audio/pratix/' + this.listeningRingtone.file;
        element.currentTime = 0;
        this.playPromise = element.play();
    }
    onViewInit() {
        this.ringtoneListener = document.getElementById('ringtone-listener');
    }
    $onDestroy() {
        if (this.playPromise !== undefined) {
            this.stopAudio(this.ringtoneListener);
        }
    }
}
exports.default = RingtoneSelectionController;
RingtoneSelectionController.$inject = [
    "$translate", "webRtcAudio"
];
