export default function chatDateTime($filter) {
  return function(input, arg="") {
    if(!input) return "";
    let date = new Date(input);
    let today = new Date();
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      return $filter('date')(date, 'HH:mm').toUpperCase();
    } else {
      return $filter('date')(date, 'dd-MM-yyyy' + arg).toUpperCase();
    }
  };
}