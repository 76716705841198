import 'angular-translate-loader-partial';
import moment from 'moment';

export default function config($translateProvider, $translatePartialLoaderProvider, $logProvider) {
  if (moment && moment.locale) {
    moment.locale(document.body.getAttribute('data-preferredlang'));
  }

  $logProvider.debugEnabled(true);

  $translatePartialLoaderProvider.addPart('xcchat');
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'assets/i18n/{part}-{lang}.json'
  });
  $translateProvider.registerAvailableLanguageKeys(['en','fr','de'], {
    'en_*': 'en',
    'fr_*': 'fr',
    'de_*': 'de'
  });
  $translateProvider.preferredLanguage(document.body.getAttribute('data-preferredlang'));
  $translateProvider.fallbackLanguage(['fr']);
  $translateProvider.forceAsyncReload(true);
}
