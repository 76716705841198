import _ from 'lodash';

export default function displayConversation(XucChat) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/displayConversation.html',
    scope: {
      remoteParty: '@'
    },
    link: (scope) => {
      scope.pendingMessages = new Map();
      scope.messages = [];

      XucChat.getRemotePartyConversation(scope.remoteParty).then(response => {
        mergeMessages(response.messages);
        XucChat.conversationDisplayed(response.remoteParty);
      });

      function mergeMessages (newMessages) {
        let mergedMessages = scope.messages.concat(
          newMessages.filter(m1 => !scope.messages.find(m2 =>
            m1.date === m2.date
            && m1.content === m2.content
          )));
        scope.messages = _.sortBy(mergedMessages, (message) => { return new Date(message.date); });
        _.defer(() => { scope.$apply(); });
      }

      scope.$on(XucChat.CHAT_RECEIVED_MESSAGE, (evt, message, remoteParty) => {
        if (scope.remoteParty == remoteParty) {
          mergeMessages([message]);
        }
      });

      scope.$on(XucChat.CHAT_SEND_PENDING_MESSAGE, (evt, message, remoteParty) => {
        if (scope.remoteParty == remoteParty) {
          scope.pendingMessages.set(message.id, message);
        }
      });

      scope.$on(XucChat.CHAT_SEND_MESSAGE_ACK, (evt, message, remoteParty) => {
        _changePendingToMessage(message, remoteParty);
      });

      scope.$on(XucChat.CHAT_SEND_MESSAGE_ACK_OFFLINE, (evt, message, remoteParty) => {
        XucChat.triggerToast('ChatOffline', 'alert-warning');
        _changePendingToMessage(message, remoteParty);
      });

      scope.$on(XucChat.CHAT_SEND_MESSAGE_NACK, (evt, message) => {
        if (scope.pendingMessages.has(message.sequence)) {
          XucChat.triggerToast('ChatError', 'alert-danger');
          scope.pendingMessages.delete(message.sequence);
        }
      });

      function _changePendingToMessage (message, remoteParty) {
        if (scope.remoteParty == remoteParty) {
          mergeMessages([message]);
          scope.pendingMessages.delete(message.id);
        }
      }

      scope.$on('$destroy', () => {
        XucChat.conversationEnded(scope.remoteParty);
      });
    }
  };
}
