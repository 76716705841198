export default function debounce($q, $timeout) {

  return function (fn, wait, invokeApply) {
    var timeout, args, context, result;
    if (!wait) wait = 500;
    function debounce() {
      /* jshint validthis:true */
      context = this;
      args = arguments;
      var later = function () {
        timeout = null;
      };
      var execNow = !timeout;
      if ( timeout ) {
        $timeout.cancel(timeout);
      }
      timeout = $timeout(later, wait, invokeApply);
      if ( execNow ) {
        result = fn.apply(context, args);
      }
      return result;
    }
    debounce.cancel = function () {
      $timeout.cancel(timeout);
      timeout = null;
    };
    return debounce;
  };
}
