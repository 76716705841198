export default function audioStatsDisplayer() {
  
  return {
    restrict: 'E',
    scope: {
      statistics: "="
    },
    templateUrl: 'assets/javascripts/xchelper/directives/audioStatsDisplayer.html',
    link: (scope) => {
      scope.showStats = false;
            
      scope.toggleAdvancedStats = () => {
        scope.showStats = !scope.showStats;
      };

    }
  };
}
