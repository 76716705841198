export default function fileReader() {
  return {
    require: "ngModel",
    link: function postLink(scope,elem,attr,ngModel) {
      elem.on("change", () => {
        let file = elem[0].files[0];
        ngModel.$setViewValue(file);
      });
    }
  };
}