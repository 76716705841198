"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallHistoryModel = exports.PhoneHintStatus = exports.VideoEvents = exports.CallStatus = void 0;
var CallStatus;
(function (CallStatus) {
    CallStatus["Emitted"] = "emitted";
    CallStatus["Answered"] = "answered";
    CallStatus["Missed"] = "missed";
    CallStatus["Ongoing"] = "ongoing";
    CallStatus["Abandoned"] = "abandoned";
    CallStatus["DivertCaRatio"] = "divert_ca_ratio";
    CallStatus["DivertWaitTime"] = "divert_waittime";
    CallStatus["Closed"] = "closed";
    CallStatus["Full"] = "full";
    CallStatus["JoinEmpty"] = "joinempty";
    CallStatus["LeaveEmpty"] = "leaveempty";
    CallStatus["Timeout"] = "timeout";
    CallStatus["ExitWithKey"] = "exit_with_key";
})(CallStatus = exports.CallStatus || (exports.CallStatus = {}));
var VideoEvents;
(function (VideoEvents) {
    VideoEvents["Available"] = "Available";
    VideoEvents["Busy"] = "Busy";
})(VideoEvents = exports.VideoEvents || (exports.VideoEvents = {}));
var PhoneHintStatus;
(function (PhoneHintStatus) {
    PhoneHintStatus[PhoneHintStatus["ONHOLD"] = 16] = "ONHOLD";
    PhoneHintStatus[PhoneHintStatus["RINGING"] = 8] = "RINGING";
    PhoneHintStatus[PhoneHintStatus["INDISPONIBLE"] = 4] = "INDISPONIBLE";
    PhoneHintStatus[PhoneHintStatus["BUSY_AND_RINGING"] = 9] = "BUSY_AND_RINGING";
    PhoneHintStatus[PhoneHintStatus["AVAILABLE"] = 0] = "AVAILABLE";
    PhoneHintStatus[PhoneHintStatus["CALLING"] = 1] = "CALLING";
    PhoneHintStatus[PhoneHintStatus["BUSY"] = 2] = "BUSY";
    PhoneHintStatus[PhoneHintStatus["DEACTIVATED"] = -1] = "DEACTIVATED";
    PhoneHintStatus[PhoneHintStatus["UNEXISTING"] = -2] = "UNEXISTING";
    PhoneHintStatus[PhoneHintStatus["ERROR"] = -99] = "ERROR";
})(PhoneHintStatus = exports.PhoneHintStatus || (exports.PhoneHintStatus = {}));
class CallHistoryModel {
    constructor() {
        this.start = undefined;
        this.status = undefined;
        this.dstVideoStatus = undefined;
        this.dstPhoneStatus = undefined;
        this.srcVideoStatus = undefined;
        this.srcPhoneStatus = undefined;
        this.duration = "";
        this.isMeetingRoom = false;
        this.callsDetail = [];
        this.initials = "";
        this.srcFirstName = "";
        this.srcLastName = "";
        this.dstFirstName = "";
        this.dstLastName = "";
        this.dstUsername = "";
        this.srcUsername = "";
        this.dstNum = 0;
        this.srcNum = 0;
    }
}
exports.CallHistoryModel = CallHistoryModel;
