"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PinCodeModal {
    constructor($uibModalInstance, JitsiProxy, token, userData) {
        this.$uibModalInstance = $uibModalInstance;
        this.JitsiProxy = JitsiProxy;
        this.token = token;
        this.userData = userData;
        this.pinCode = '';
        this.onKeyPress = (keyCode) => {
            if (keyCode == 13) {
                this.onSubmitPinModal();
            }
        };
        this.cancel = () => {
            this.$uibModalInstance.dismiss('cancel');
        };
        this.onSubmitPinModal = () => {
            this.JitsiProxy.validateToken(this.token, this.userData, this.pinCode).then((result) => {
                this.$uibModalInstance.close(result.data.token);
            }, () => (this.showError = true));
        };
        this.showError = false;
    }
}
exports.default = PinCodeModal;
