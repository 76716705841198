export default function onHoldNotifier($interval, $q, XucPhoneState) {
  var ongoing;
  var counters = new Map();

  const _notify = (threshold, call) => {
    var deferred = $q.defer();

    counters.set(call.uniqueId, 0);

    if (!ongoing) {
      ongoing = $interval(() => {
        let calls = XucPhoneState.getCallsOnHold();
        let call = calls.map(c => {
          counters.set(c.uniqueId, counters.get(c.uniqueId)+1);
        }).reduce((c1, c2) => {
          if (!c2) return c1;
          return counters.get(c1.uniqueId) > counters.get(c2.uniqueId) ? c1 : c2;
        }, calls.values().next().value);

        if (call){
          deferred.notify({count:counters.get(call.uniqueId), call:call});
        }
        else {
          deferred.resolve();
        }
      }, threshold, 0, false);
    }

    return deferred.promise;
  };

  const _stop = () => {
    let calls = XucPhoneState.getCallsOnHold();
    if (!calls.length && angular.isDefined(ongoing)) {
      $interval.cancel(ongoing);
      ongoing = undefined;
      counters.clear();
    }
  };

  return {
    notify: _notify,
    stop: _stop
  };
}