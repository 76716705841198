"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BrowserService {
    constructor($window, $uibModal) {
        this.$window = $window;
        this.$uibModal = $uibModal;
        this._browser = 'unknown';
        this.modalInstance = undefined;
        let userAgent = $window.navigator.userAgent;
        switch (true) {
            case userAgent.toLowerCase().indexOf('edge') > -1:
                this._browser = 'edge';
                break;
            case userAgent.toLowerCase().indexOf('opr') > -1 && !!window.opr:
                this._browser = 'opera';
                break;
            case userAgent.toLowerCase().indexOf('chrome') > -1 && !!window.chrome:
                this._browser = 'chrome';
                break;
            case userAgent.toLowerCase().indexOf('trident') > -1:
                this._browser = 'ie';
                break;
            case userAgent.toLowerCase().indexOf('firefox') > -1:
                this._browser = 'firefox';
                break;
            case userAgent.toLowerCase().indexOf('safari') > -1:
                this._browser = 'safari';
                break;
            default:
                this._browser = 'other';
        }
    }
    get browser() {
        return this._browser;
    }
    compatibilityAlert() {
        this.modalInstance = this.$uibModal.open({
            templateUrl: 'assets/javascripts/xchelper/controllers/alertBrowserCompatibility.controller.html',
            size: 'sm',
            controller: 'NotificationModalController',
            resolve: {}
        });
        this.modalInstance.result.catch(() => {
            this.modalInstance = undefined;
        });
    }
}
exports.default = BrowserService;
