export default function containerResizable($timeout, $rootScope, $state, $window, XucPhoneEventListener, JitsiProxy) {
  return {
    restrict: 'A',
    scope: {},
    link(scope, elem) {
      const timeoutInMs = 150;
      var resizeOnShowPhoneBarTimeout;

      const _autoResize = () => {
        // force $digest to be stacked to run resize $watch
        $timeout(() => { }, 0);
      };

      XucPhoneEventListener.addReleasedHandler($rootScope, _autoResize);
      JitsiProxy.subscribeToShowPhoneBar(scope, () => {
        resizeOnShowPhoneBarTimeout = $timeout(() => {
          getAndApplyChanges();
        },timeoutInMs);
      });

      const getHeader = () => { return elem[0].querySelector('.header-container');};
      const getContentView = () => { return elem[0].querySelector('.content-view');};
      
      const getFooterSize = () => {
        let footer = elem[0].querySelector('.footer');
        return footer == null || undefined ? 0 : footer.offsetHeight;
      };

      const getUcCallControlSize = () => {
        let UcCallControl = elem[0].querySelector('.calls-outside-header');
        return UcCallControl == null || undefined ? 0 : UcCallControl.offsetHeight;
      };

      scope.applySize = (elemSize, contentView, change) => {
        contentView.style.height = (elemSize - change) + 'px';
      };
      
      const getAndApplyChanges = () => {
        let headerSize = getHeader().offsetHeight;
        let footerSize = getFooterSize();
        let ucCallControlSize = getUcCallControlSize();
        let total = headerSize + footerSize + ucCallControlSize;
        scope.applySize(elem[0].offsetHeight, getContentView(), total);
      };
      
      scope.$on('$destroy', function() {
        $timeout.cancel(resizeOnShowPhoneBarTimeout);
      });

      angular.element($window).bind('resize', function () {
        getAndApplyChanges();
        if (!$rootScope.$$phase) scope.$apply();
      });

      scope.$watchGroup(
        [
          function () { return $state.current.url; },
          function () { return getUcCallControlSize(); },
          function () { return getHeader().offsetHeight; },
          function () { return getContentView().offsetHeight; }
        ], function () {
          getAndApplyChanges();
        });

    }
  };
}