import _ from 'lodash';

export default function XucQueueRecording($rootScope, $http, $log, $q, XucLink, XucQueue) {
  const timeout = 5000;
  const recordingMode = 'recording_mode';
  const recordingModeTypes = ['recorded', 'recordedondemand'];

  const allRecorded = "all";
  const someRecorded = "some";
  const noneRecorded = "none";

  var _configManagerError = false;
  var _recordingQueues = [];


  var _getToken = (func) => {
    return XucLink.whenLogged().then((user) => {
      let headers = {headers: { 'Authorization': 'Bearer ' + user.token }, timeout: timeout};
      return func(headers);
    });
  };

  var _hasRight = (func) => {
    return XucLink.getProfileRightAsync().then(rightProfile => {
      if (XucLink.isRightAllowed(rightProfile, 'recording')) {
        return func;
      } else {
        let deferred = $q.defer();
        deferred.reject();
        return deferred.promise;
      }
    });
  };

  var _getUrl = (state) => {
    let param = state ? '/'+state : '';
    return XucLink.getServerUrl('http')+'/xuc/api/2.0/config/recording/status'+param;
  };

  var _setState = (state) => {
    let apiCall = (headers) => {
      return $http.post(_getUrl(state), {}, headers).then(
        () => {_configManagerError = false;},
        () => {_configManagerError = true ;}
      );
    };

    return _hasRight(_getToken(apiCall));
  };

  var _getState = () => {
    let deferred = $q.defer();

    _hasRight(_getRecordingQueuesAsync()).then(recQueues => {
      deferred.resolve(_expectedState(recQueues));
    }, (() => { return deferred.reject("NO_RECORDING_RIGHT"); }) );
    return deferred.promise;
  };

  var _getRecordingQueueInfo = (queueId) => {
    let recQueue = XucQueue.getQueue(queueId);
    if (!recQueue) return {};
    return {
      activated: recQueue.recording_activated,
      mode: recQueue.recording_mode
    };
  };

  var _isRecordingMode = (mode) => {
    return _.includes(recordingModeTypes, mode);
  };

  var _expectedState = (recQueues) => {
    return _isAllRecorded(recQueues) ? allRecorded :
      _isNoneRecorded(recQueues) ? noneRecorded : someRecorded;
  };

  var _isAllRecorded = (recQueues) => {
    return _.every(recQueues, 'recording_activated');
  };

  var _isNoneRecorded = (recQueues) => {
    return _.every(recQueues, ['recording_activated', 0]);
  };

  var _getRecordingQueuesAsync = () => {
    return XucQueue.getQueuesAsync().then(queues => {
      const recQueues = _.filter(queues, function(q) {
        return _isRecordingMode(q[recordingMode]);
      });
      _recordingQueues = recQueues;
      return recQueues;
    });
  };

  var _getRecordingQueues = () => {
    return _recordingQueues;
  };

  var _processQueueConfig = (qConfig) => {
    _getRecordingQueuesAsync().then(queues => {

      if (_isRecordingMode(qConfig[recordingMode])) {
        queues.push(qConfig);
      }

      $rootScope.$broadcast('RecordingStateUpdated', _expectedState(queues));
    });
  };

  var _subscribeToQueueConfig = (scope, callback) => {
    var handler = $rootScope.$on('RecordingStateUpdated', (event, activation) => {
      callback(activation);
    });
    scope.$on('$destroy', handler);
  };

  var _handleError = (error) => {

    if (error == 'NO_RECORDING_RIGHT') {
      $log.debug("No recording right found, recording feature has been disabled");
    }
    else {
      _configManagerError = true;
    }
  };

  var _hasConfigManagerError = () => {
    return _configManagerError;
  };

  this.uninit = function() {
    $log.info("Unloading XucQueueRecording service");
    _configManagerError = false;
    _recordingQueues = [];
    XucLink.whenLogged().then(this.init.bind(this));
  };

  this.init = function() {
    $log.info("Starting XucQueueRecording service");
    XucLink.whenLoggedOut().then(this.uninit.bind(this));
  };

  Cti.setHandler(Cti.MessageType.QUEUECONFIG, _processQueueConfig);
  XucLink.whenLogged().then(this.init.bind(this));

  return {
    getState: _getState,
    setState: _setState,
    getRecordingQueueInfo: _getRecordingQueueInfo,
    getRecordingQueuesAsync: _getRecordingQueuesAsync,
    getRecordingQueues: _getRecordingQueues,
    processQueueConfig: _processQueueConfig,
    subscribeToQueueConfig: _subscribeToQueueConfig,
    handleError: _handleError,
    hasConfigManagerError: _hasConfigManagerError,
    ALL_RECORDED: allRecorded,
    SOME_RECORDED: someRecorded,
    NONE_RECORDED: noneRecorded
  };

}