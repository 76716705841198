import moment from 'moment';

export default function elapsedTimeFrom($interval) {
  return function(scope, element, attrs) {
    var startTime, stopTime;

    function updateTime() {
      if(typeof(startTime) !== "undefined") {
        var duration = moment().diff(moment(startTime));
        if (duration <  "3600000") {
          element.text(moment.utc(duration).format("mm:ss"));
          //element.text(moment.utc(duration).format("H:mm:ss"));
        } else {
          element.text(moment.utc(duration).format("H:mm:ss"));
        }
      } else {
        element.text("-:--:--");
      }
    }

    scope.$watch(attrs.elapsedTimeFrom, function(value) {
      startTime = value;
      updateTime();
    });

    stopTime = $interval(updateTime, 1000);

    element.bind('$destroy', function() {
      $interval.cancel(stopTime);
    });

  };
}

