export default function ringtoneConfiguration(webRtcAudio, $translate) {
  
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/ringtoneConfiguration.html',
    controller: ($scope) => {
      
      $scope.ringtones = [
        {id: 1, name: $translate.instant('DEFAULT_RINGTONE_NAME'), file: 'incoming_call.mp3'},
        {id: 2, name: 'Alexander Nakarada - The Return', file: 'alexander_nakarada_the_return.mp3'},
        {id: 3, name: 'Keys of Moon - The Success', file: 'keys_of_moon_the_success.mp3'},
        {id: 4, name: 'Another Happy Ukulele Song', file: 'another_happy_ukulele_song.mp3'},
        {id: 5, name: 'Cbakos - Cordless Phone Ring', file: 'cbakos_cordless_phone_ring.wav'},
        {id: 6, name: 'Sandor Molnar - Fekete Rigo', file: 'sandor_molnar_fekete_rigo.mp3'},
        {id: 7, name: 'Imthemap - Pop Ringtone', file: 'imthemap_pop_ringtone.wav'},
        {id: 8, name: 'Inchadney - British Telephone', file: 'inchadney_british_telephone.wav'},
      ];

      $scope.selectedRingtone = JSON.parse(window.localStorage.getItem('user_ringtone')) || $scope.ringtones[0];

      $scope.changeRingtone = ($model) => {
        $scope.selectedRingtone = $model;
        window.localStorage.setItem('user_ringtone', JSON.stringify($scope.selectedRingtone));
        webRtcAudio.changeRingtone($model);
      };
    }
  };
}