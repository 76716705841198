import uibootstrap from 'angular-ui-bootstrap';
import uirouter from '@uirouter/angularjs';
import angulartranslate from 'angular-translate';
import newMessageBadge from './directives/newMessageBadge.directive';
import callRemoteParty from './directives/callRemoteParty.directive';
import goBackArrow from './directives/goBackArrow.directive';
import displayConversation from './directives/displayConversation.directive';
import displayConversationHistory from './directives/displayConversationHistory.directive';
import sendMessage from './directives/sendMessage.directive';
import chatDateTime from './filters/chatDateTime.filter';
import config from './chat.config';
import 'angular-emoji-filter-hd';
import 'angular-emoji-filter-hd/dist/emoji.min.css';

angular.module('xcChat', [uirouter, uibootstrap, angulartranslate, 'dbaq.emoji', 'ngSanitize'])
  .config(config)
  .directive('newMessageBadge', newMessageBadge)
  .directive('callRemoteParty', callRemoteParty)
  .directive('goBackArrow', goBackArrow)
  .directive('displayConversation', displayConversation)
  .directive('displayConversationHistory', displayConversationHistory)
  .directive('sendMessage', sendMessage)
  .filter('chatDateTime', chatDateTime);
