export default class OnHoldPopup {

  constructor($scope, $uibModalInstance, XucPhoneEventListener, CtiProxy, callInfo) {
    var _closing = false;

    $scope.accept = () => {
      _closing = true;
      CtiProxy.hold(callInfo.uniqueId);
      $uibModalInstance.dismiss('accept');
    };

    $scope.decline = () => {
      _closing = true;
      $uibModalInstance.dismiss('decline');
    };

    const _autoClose = () => {
      if(!_closing) {
        _closing = true;
        $uibModalInstance.dismiss('autoclose');
      }
    };

    XucPhoneEventListener.addEstablishedHandler($scope, _autoClose);
    XucPhoneEventListener.addReleasedHandler($scope, _autoClose);

  }
}
