"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPreferencePreferredDevice = exports.UserPreferenceBoolean = exports.UserPreferenceValueType = exports.UserPreferenceKey = void 0;
var UserPreferenceKey;
(function (UserPreferenceKey) {
    UserPreferenceKey["PreferredDevice"] = "PREFERRED_DEVICE";
    UserPreferenceKey["MobileAppInfo"] = "MOBILE_APP_INFO";
    UserPreferenceKey["NbMissedCall"] = "NB_MISSED_CALL";
})(UserPreferenceKey = exports.UserPreferenceKey || (exports.UserPreferenceKey = {}));
var UserPreferenceValueType;
(function (UserPreferenceValueType) {
    UserPreferenceValueType["string"] = "String";
    UserPreferenceValueType["boolean"] = "Boolean";
})(UserPreferenceValueType = exports.UserPreferenceValueType || (exports.UserPreferenceValueType = {}));
var UserPreferenceBoolean;
(function (UserPreferenceBoolean) {
    UserPreferenceBoolean["True"] = "true";
    UserPreferenceBoolean["False"] = "false";
})(UserPreferenceBoolean = exports.UserPreferenceBoolean || (exports.UserPreferenceBoolean = {}));
var UserPreferencePreferredDevice;
(function (UserPreferencePreferredDevice) {
    UserPreferencePreferredDevice["WebApp"] = "WebApp";
    UserPreferencePreferredDevice["MobileApp"] = "MobileApp";
    UserPreferencePreferredDevice["WebAppAndMobileApp"] = "WebAppAndMobileApp";
})(UserPreferencePreferredDevice = exports.UserPreferencePreferredDevice || (exports.UserPreferencePreferredDevice = {}));
