"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Invitation = void 0;
class Invitation {
    constructor(requestId, displayName, timeout) {
        this.requestId = requestId;
        this.displayName = displayName;
        this.timeout = timeout;
    }
}
exports.Invitation = Invitation;
class MeetingroomsInviteManager {
    constructor($window, $rootScope, XucLink, $log) {
        var _a, _b;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.XucLink = XucLink;
        this.$log = $log;
        this.NEW_INVITATION_COUNT_EVENT = "NewInvitationCountEvent";
        this.DISPLAY_INVITATION_TOAST_EVENT = "DisplayInvitationToastEvent";
        this.TOAST_UNAVAILABLE_MESSAGE = "TOAST_UNAVAILABLE_MESSAGE";
        this.TOAST_ACCEPTED_MESSAGE = "TOAST_ACCEPTED_MESSAGE";
        this.TOAST_REJECTED_MESSAGE = "TOAST_REJECTED_MESSAGE";
        this.TOAST_EXPIRATION_MESSAGE = "TOAST_EXPIRATION_MESSAGE";
        this.invitations = [];
        this.inviteSequence = 0;
        this.timeout = 15000;
        this.init = () => {
            this.$log.info("Starting MeetingroomInviteManager service");
            this.XucLink.whenLoggedOut().then(this.unInit);
        };
        this.unInit = () => {
            this.$log.info("Unloading MeetingroomInviteManager service");
            this.invitations = [];
            this.XucLink.whenLogged().then(this.init);
        };
        this.onAck = (ack) => {
            switch (ack.ackType) {
                case "NACK":
                    this.$rootScope.$broadcast(this.DISPLAY_INVITATION_TOAST_EVENT, this.TOAST_UNAVAILABLE_MESSAGE, ack.displayName);
                    this.removeInvitation(ack.requestId);
                    break;
            }
        };
        this.onResponse = (response) => {
            this.removeInvitation(response.requestId);
            switch (response.responseType) {
                case "Accept":
                    this.notifyToaster(this.DISPLAY_INVITATION_TOAST_EVENT, this.TOAST_ACCEPTED_MESSAGE, response.displayName);
                    break;
                case "Reject":
                    this.notifyToaster(this.DISPLAY_INVITATION_TOAST_EVENT, this.TOAST_REJECTED_MESSAGE, response.displayName);
                    break;
            }
        };
        this.sendInvitation = (username, displayName, token) => {
            this.inviteSequence += 1;
            this.$window.Cti.inviteToMeetingRoom(this.inviteSequence, token, username);
            this.invitations.push(new Invitation(this.inviteSequence, displayName, setTimeout(this.onExpiration.bind(this, this.inviteSequence, displayName), this.timeout)));
            this.$rootScope.$broadcast(this.NEW_INVITATION_COUNT_EVENT, this.invitations.length);
        };
        this.onExpiration = (requestId, displayName) => {
            this.notifyToaster(this.DISPLAY_INVITATION_TOAST_EVENT, this.TOAST_EXPIRATION_MESSAGE, displayName);
            this.removeInvitation(requestId);
        };
        this.notifyToaster = (event, message, displayName) => {
            this.$rootScope.$broadcast(event, message, displayName);
        };
        this.removeInvitation = (requestId) => {
            var _a;
            let timeoutRef = (_a = this.invitations
                .find(inv => inv.requestId == requestId)) === null || _a === void 0 ? void 0 : _a.timeout;
            this.$window.clearTimeout(timeoutRef);
            this.invitations = this.invitations.filter(inv => inv.requestId != requestId);
            this.$rootScope.$broadcast(this.NEW_INVITATION_COUNT_EVENT, this.invitations.length);
        };
        this.acceptInvitation = (inviteSequence, username, token) => {
            this.$window.Cti.meetingRoomInviteAccept(inviteSequence, username);
        };
        this.rejectInvitation = (inviteSequence, username) => {
            this.$window.Cti.meetingRoomInviteReject(inviteSequence, username);
        };
        this.XucLink.whenLogged().then(this.init);
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setHandler(this.$window.Cti.MessageType.MEETINGROOMACK, this.onAck.bind(this));
        (_b = this.$window.Cti) === null || _b === void 0 ? void 0 : _b.setHandler(this.$window.Cti.MessageType.MEETINGROOMRESPONSE, this.onResponse.bind(this));
    }
}
exports.default = MeetingroomsInviteManager;
MeetingroomsInviteManager.$inject = ["$window", "$rootScope", "XucLink", "$log"];
