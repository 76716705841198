export default function ($provide) {
  $provide.decorator('$log', function ( $delegate ){

    const originalError = $delegate.error;
    $delegate.error = function xucErrorPropagation(error, args, sendToXuc = false) {
      if (sendToXuc) Cti.pushLogToServer('error', error);
      if (args) originalError.apply($delegate, [...arguments].slice(0, 2));
      else originalError.apply($delegate, [...arguments].slice(0, 1));
    };

    const originalWarn = $delegate.error;
    $delegate.error = function xucWarnPropagation(warn, args, sendToXuc = false) {
      if (sendToXuc) Cti.pushLogToServer('warn', warn);
      if (args) originalWarn.apply($delegate, [...arguments].slice(0, 2));
      else originalWarn.apply($delegate, [...arguments].slice(0, 1));
    };

    const originalInfo = $delegate.info;
    $delegate.info = function xucInfoPropagation(info, args, sendToXuc = false) {
      if (sendToXuc) Cti.pushLogToServer('info', info);
      if (args) originalInfo.apply($delegate, [...arguments].slice(0, 2));
      else originalInfo.apply($delegate, [...arguments].slice(0, 1));
    };

    const originalDebug = $delegate.debug;
    $delegate.debug = function xucDebugPropagation(debug, args, sendToXuc = false) {
      if (sendToXuc) Cti.pushLogToServer('debug', debug);
      if (args) originalDebug.apply($delegate, [...arguments].slice(0, 2));
      else originalDebug.apply($delegate, [...arguments].slice(0, 1));
    };

    // For compatibility with angularjs $log provider
    $delegate.error.logs = [];
    $delegate.info.logs = [];
    $delegate.warn.logs = [];
    $delegate.debug.logs = [];

    return $delegate;
  });
}