import _ from 'lodash';

export class Conversation {
  constructor(remoteParty, messages) {
    this.remoteParty = remoteParty;
    this.messages = messages;
  }

  toJSON() {
    return {
      remoteParty: this.remoteParty,
      messages: this.messages.map(e => e.toJSON()),
    };
  }
}

export class Message {
  constructor(sequence, content, date, direction) {
    this.id = sequence;
    this.content = content;
    this.direction = direction;
    this.date = date;
  }

  toJSON() {
    return {
      id: this.id,
      content: this.content,
      direction: this.direction,
      date: this.date
    };
  }
}

export class MessageHistory {
  constructor(content, date, unreadMessageCount) {
    this.content = content;
    this.date = date;
    this.unreadMessageCount = unreadMessageCount;
  }

  toJSON() {
    return {
      content: this.content,
      date: this.date,
      unreadMessageCount : this.unreadMessageCount
    };
  }
}

export default function XucChat($rootScope, $log, XucLink, $q, $translate, toast) {

  const _CHAT_RECEIVED_MESSAGE = 'ChatReceivedMessage';
  const _CHAT_SEND_PENDING_MESSAGE = 'ChatSendPendingMessage';
  const _CHAT_SEND_MESSAGE_ACK = 'ChatPendingAck';
  const _CHAT_SEND_MESSAGE_ACK_OFFLINE = 'ChatSendMessageAckOffline';
  const _CHAT_SEND_MESSAGE_NACK = 'ChatSendMessageNack';
  const _CHAT_UNREAD_MESSAGE = 'ChatUnreadMessage';

  const toastDuration = 3000;
  var currentMessageSequence = 0;
  var pendingRequest = new Map();
  var conversationHistoryCache = new Map();
  var currentRemoteParty;
  var unreadMessagesCount = 0;

  const _triggerToast = (message, className) => {
    toast({
      duration: toastDuration,
      message: $translate.instant(message),
      className: className,
      position: "center",
      container: '.toast-container'
    });
  };

  const _getSequence = () => {
    currentMessageSequence = currentMessageSequence + 1;
    return currentMessageSequence;
  };

  const _processSendMessage = (remoteParty, messageText, sequence) => {
    var deferred = $q.defer();
    pendingRequest.set(sequence, deferred);
    Cti.sendFlashTextMessage(remoteParty, sequence, messageText);
    return deferred.promise;
  };

  const _sendMessage = (remoteParty, messageText) => {
    let sequence = _getSequence();
    let message = new Message (sequence, messageText, '', 'outgoing');
    $rootScope.$broadcast(_CHAT_SEND_PENDING_MESSAGE, message, remoteParty);
    _processSendMessage(remoteParty, messageText, sequence).then((result) => {
      message.date = result.date;
      conversationHistoryCache.set(remoteParty, new MessageHistory(message.content, message.date, 0));
      (result.offline) ?
        $rootScope.$broadcast(_CHAT_SEND_MESSAGE_ACK_OFFLINE, message, remoteParty) :
        $rootScope.$broadcast(_CHAT_SEND_MESSAGE_ACK, message, remoteParty);
    }, (error) => {
      $log.error("Send message failed due to error", error);
    });
  };

  const onSendMessageAckReceived = (data) => {
    if (pendingRequest.has(data.sequence)) {
      pendingRequest.get(data.sequence).resolve(data);
      pendingRequest.delete(data.sequence);
    }
    else {
      $log.error("Unknown Ack received, no such sequence id in the map", data.sequence);
    }
  };

  const onSendMessageNackReceived = (data) => {
    if (pendingRequest.has(data.sequence)) {
      pendingRequest.get(data.sequence).reject("Failed to send message");
      pendingRequest.delete(data.sequence);
    }
    else {
      $log.error("Unknown Nack received, no such sequence id in the map", data.sequence);
    }
    $rootScope.$broadcast(_CHAT_SEND_MESSAGE_NACK, data);
  };

  const _getConversationHistory = () => {
    return conversationHistoryCache;
  };

  const _getRemotePartyConversation = (remoteParty) => {
    let sequence = _getSequence();
    Cti.getFlashTextPartyHistory(remoteParty, sequence);
    var deferred = $q.defer();
    pendingRequest.set(sequence, deferred);
    return deferred.promise;
  };

  const convertHistoryToConversation = (data) => {
    let remoteParty = data.users[1].username;
    var messages = _.map(data.messages, function(message) {
      var direction = message.from.username == data.users[0].username ? 'outgoing' : 'incoming';
      return new Message(message.sequence, message.message, message.date, direction);
    });
    return new Conversation(remoteParty, messages);
  };

  const onRemotePartyHistoryReceived = (data) => {
    if (pendingRequest.has(data.sequence)) {
      let conversation = convertHistoryToConversation(data);
      pendingRequest.get(data.sequence).resolve(conversation);
      pendingRequest.delete(data.sequence);
    }
  };

  const _conversationDisplayed = (remoteParty) => {
    currentRemoteParty = remoteParty;
    Cti.markFlashTextAsRead(remoteParty, _getSequence());
    let item = conversationHistoryCache.get(remoteParty);
    if (item) item.unreadMessageCount = 0;
    notifyUnreadCounter();
  };

  const _conversationEnded = () => {
    Cti.markFlashTextAsRead(currentRemoteParty, _getSequence());
    currentRemoteParty = undefined;
  };

  const notifyUnreadCounter = () => {
    let counter = Array.from(conversationHistoryCache.values())
      .filter(c => c.unreadMessageCount > 0)
      .length;
    unreadMessagesCount = counter;
    $rootScope.$broadcast(_CHAT_UNREAD_MESSAGE, counter);
  };

  const onMessageReceived = (data) => {
    let remoteParty = data.from.username;
    let item = conversationHistoryCache.get(remoteParty);
    let counter = (item) ? item.unreadMessageCount : 0;
    if (remoteParty != currentRemoteParty) counter++;
    conversationHistoryCache.set(remoteParty, new MessageHistory(data.message, data.date, counter));
    $rootScope.$broadcast(_CHAT_RECEIVED_MESSAGE, new Message (data.sequence, data.message, data.date, 'incoming'), remoteParty, counter > 0);
    notifyUnreadCounter();
  };

  const _getUnreadMessagesCount = () => {
    return unreadMessagesCount;  
  };

  const _onFlashTextEvent = (data) => {
    switch(data.event) {
    case 'FlashTextUserMessage':
      onMessageReceived(data);
      break;
    case 'FlashTextSendMessageAck':
    case 'FlashTextSendMessageAckOffline':
      onSendMessageAckReceived(data);
      break;
    case 'FlashTextSendMessageNack':
      onSendMessageNackReceived(data);
      break;
    case 'FlashTextUserMessageHistory':
      onRemotePartyHistoryReceived(data);
      break;
    case 'FlashTextUnreadMessages':
      data.messages.forEach(m => onMessageReceived(m));
      break;
    }
  };

  const init = () => {
    $log.info("Starting XucChat service");
    XucLink.whenLoggedOut().then(_uninit);
  };

  const _uninit = () => {
    $log.info("Unloading XucChat service");
    XucLink.whenLogged().then(init);
    conversationHistoryCache.clear();
    pendingRequest.clear();
    currentRemoteParty = undefined;
  };

  Cti.setHandler(Cti.MessageType.FLASHTEXTEVENT, _onFlashTextEvent);

  XucLink.whenLogged().then(init);

  return {
    CHAT_SEND_PENDING_MESSAGE: _CHAT_SEND_PENDING_MESSAGE,
    CHAT_SEND_MESSAGE_ACK: _CHAT_SEND_MESSAGE_ACK,
    CHAT_SEND_MESSAGE_ACK_OFFLINE: _CHAT_SEND_MESSAGE_ACK_OFFLINE,
    CHAT_SEND_MESSAGE_NACK: _CHAT_SEND_MESSAGE_NACK,
    CHAT_RECEIVED_MESSAGE: _CHAT_RECEIVED_MESSAGE,
    CHAT_UNREAD_MESSAGE: _CHAT_UNREAD_MESSAGE,
    getConversationHistory : _getConversationHistory,
    getRemotePartyConversation : _getRemotePartyConversation,
    conversationDisplayed: _conversationDisplayed,
    conversationEnded: _conversationEnded,
    sendMessage:_sendMessage,
    triggerToast: _triggerToast,
    getUnreadMessagesCount: _getUnreadMessagesCount
  };
}
