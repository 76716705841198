var login = angular.module('xcLogin', ['ui.router', 'ui.bootstrap', 'pascalprecht.translate']);
login.config(['$translateProvider', '$translatePartialLoaderProvider', function($translateProvider, $translatePartialLoaderProvider) {
  $translatePartialLoaderProvider.addPart('xclogin');
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'assets/i18n/{part}-{lang}.json'
  });
  $translateProvider.registerAvailableLanguageKeys(['en','fr','de'], {
    'en_*': 'en',
    'fr_*': 'fr',
    'de_*': 'de'
  });
  $translateProvider.preferredLanguage(document.body.getAttribute('data-preferredlang'));
  $translateProvider.fallbackLanguage(['fr']);
  $translateProvider.forceAsyncReload(true);
}]);
