

export default function BarChart($translate, $filter) {
  return {
    restrict: 'E',
    scope: {
      data: "=",
      width: "@",
      height: "@"
    },
    template: '<nvd3 options="options" data="barChartData"></nvd3>',
    link: (scope) => {

      scope.options = {
        chart: {
          type: 'discreteBarChart',
          height: 300,
          staggerLabels: true,
          tooltip: {  
            classes: 'nvtooltip-zindex',
            keyFormatter: function(key){return $translate.instant(key) ;},  
            valueFormatter: function(value){return $filter('date')(new Date(0, 0, 0).setSeconds(value || 0), "HH:mm:ss") ;},
            gravity: 's'
          },
          showXAxis: false,
          showYAxis: true,
          width: 320,
          color:["#5cb85c", "#d9534f","#3c3cbc","#a34719", "#f7612f"],
          
          x: function(d){return d.label;},
          y: function(d){return d.value;},          
          showValues: false,         
          
          yAxis: {
            tickFormat: function(d) { return $filter('date')(new Date(0, 0, 0).setSeconds(d || 0), "HH:mm") ;},
          },
                
          duration: 500,
        }
      };

      scope._getValue = function(item) {
        if (angular.isDefined(item.rawValue)) {
          return item.rawValue;
        } else {
          return item.value;
        }
      };

      scope.fillData = function() {        
        scope.barChartValues = [];
        angular.forEach(scope.data, function(item) {
          var newItem = {};
          newItem.value = scope._getValue(item);
          newItem.label = item.id;
          scope.barChartValues.push(newItem);
        });
        scope.barChartData = [{key: 'title', values: scope.barChartValues}];  
      };

      scope.fillData();

      scope.$watchCollection('data', function(newValue, oldValue) {
        if (newValue == oldValue) return;
        scope.fillData();
      });
    }
  };
}
  
 