export default function onHold($rootScope, $uibModal, $log) {
  var _scope = $rootScope.$new(true);
  var _modalInstance = null;

  var _init = () => {};

  var _showModal = () => {
    return $uibModal.open({
      templateUrl: 'assets/javascripts/xchelper/services/onhold.html',
      size: 'sm',
      controller: 'OnHoldPopup',
      scope: _scope,
      resolve: {
        callInfo: function () {
          return _scope.callInfo;
        },
      }
    });
  };

  $rootScope.$on('OnHoldNotification', (event, caller, timer, uniqueId) => {
    $log.debug('OnHoldNotification received');

    _scope.callInfo = {
      caller: caller,
      timer: timer,
      uniqueId: uniqueId
    };
    if (_modalInstance !== null) {
      $log.debug('OnHold modal already displayed, discard new one');
      return;
    }

    _modalInstance = _showModal();
    _modalInstance.result.catch(() => {
      $log.debug("an error occurred while closing onHold modal");
    }).finally(() => {
      _modalInstance = null;
      $log.debug("onHold modal closed");
    });
  });

  return {init: _init};
}
