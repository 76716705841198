export default function longText($compile){
  return {
    restrict: 'A',
    template: '',
    compile: function() {
      return {
        pre: function(scope, elem, attr) {
          scope.attr = attr;
          function truncateText() {
            var ellipsis = '…';
            var maxTotalCharacters = parseInt(attr.maxChars, 10);
            var maxCharRegex = new RegExp('.{' + maxTotalCharacters + '}');
            var wrapPrefix = ''; var wrapSuffix = '';
            if (!attr.position) attr.position = 'top';
            function setWrapperElements() {
              wrapPrefix = '<span title="'+attr.longText+'" data-toggle="tooltip" data-placement="'+attr.position+'">';
              wrapSuffix = '</span>';
            }
            var cleanedText = attr.longText.replace(/<[^>]*?>/g);
            if(maxTotalCharacters && cleanedText.length > maxTotalCharacters) {
              setWrapperElements();
              cleanedText = maxCharRegex.exec(cleanedText) + ellipsis;
            }
            elem.html(wrapPrefix + cleanedText + wrapSuffix);
            $compile(elem.contents())(scope);
          }
          scope.$watch('attr.longText', truncateText);
          scope.$watch('attr.maxChars', truncateText);
        }
      };
    },
    // postlink fn
    link: function(scope, element){
      angular.element(element).hover(function(){
        // on mouseenter
        angular.element(element).tooltip('show');
      }, function(){
        // on mouseleave
        angular.element(element).tooltip('hide');
      });
    }
  };
}

