import 'lodash';
import 'angular';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-sanitize';
import 'angular-animate';
import './simple-notification-global';
import 'angular-web-notification';
import 'angularjs-toast';
import 'jquery';


import './cti.module';
import './services/ctiProxy.factory';
