angular.module('xcLogin').controller('FatalErrorController', function($scope, $state, $uibModalInstance, errorMessage, XucLink, CtiProxy, showSwitchModal) {
  $scope.errorMessage = errorMessage;
  $scope.showSwitchModal = showSwitchModal;

  var logout = function() {
    CtiProxy.stopUsingWebRtc();
    XucLink.logout();
    if(errorMessage === 'CTI_SOCKET_CLOSED_WHILE_USING_WEBRTC') {
      $state.go('login', {error: 'LinkClosed'});
    } else {
      $state.go('login', {error: 'Logout'});
    }
  };

  $uibModalInstance.result.then(function (logoutReturnState ) {
    if (logoutReturnState === true) {
      logout();
    }
  }, function (logoutReturnState) {
    if (logoutReturnState === true) {
      logout();
    }
  });

  $scope.close = function(state) {
    $uibModalInstance.close(state);
    if (state === true) {
      logout();
    }
  };
});

