
export default function sendMessage(XucChat) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/sendMessage.html',
    scope: {
      remoteParty: '@'
    },
    link: (scope) => {

      angular.element('.chat-text').focus();

      scope.checkForEnter = ($event) => {
        if ($event.keyCode === 13) {
          $event.preventDefault();
          if (scope.areaMessage && scope.areaMessage != '') {
            $event.shiftKey ? scope.areaMessage += '\n' : scope.sendMessage(scope.remoteParty);
          }
        }
      };

      scope.sendMessage = () => {
        XucChat.sendMessage(scope.remoteParty, scope.areaMessage);
        scope.areaMessage = '';
      };
    }
  };
}
