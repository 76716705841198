export default function isPhoneNumber(XucUtils) {

  return {
    require: 'ngModel',
    link: function($scope, elm, attrs, ctrl) {
      ctrl.$validators.isPhoneNumber = function(modelValue, viewValue) {
       
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }

        return XucUtils.isaPhoneNb(XucUtils.normalizePhoneNb(viewValue));
      }; 
    }
  };
}

      