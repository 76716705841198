import _ from 'lodash';

export default function callActionDropdown(applicationConfiguration, XucPhoneState, XucPhoneEventListener, keyNavUtility, $state, CtiProxy, $translate, XucUtils, callContext,$window, JitsiProxy, $log, toast){
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/callActionDropdown.html',
    scope: {
      contact: "=",
      phoneNumber: "=",
      icon: "@",
      ellipsis: "=",
      emailMaxChars: '@'
    },
    link: (scope) => {
      scope.appConfig = applicationConfiguration.getCurrentAppConfig();
      scope.phoneNumbers = [];
      scope.contact.displayName = scope.contact.entry[0];
      
      const init = () => {
        if(_.isObject(scope.contact)) {
          _.forEach([1, 2, 3], (index) => {
            if (!_.isEmpty(scope.contact.entry[index])) {
              scope.phoneNumbers.push(scope.contact.entry[index]);
            }
          });
          
          if (!_.isEmpty(scope.contact.entry[5])) {
            scope.email = scope.contact.entry[5];
            generateMailToLink();
            XucPhoneEventListener.addEstablishedHandler(scope, generateMailToLink);
            XucPhoneEventListener.addReleasedHandler(scope, generateMailToLink);
          }
        }
      };

      scope.isMeetingRoom = () => {
        return callContext.isMeetingRoom(scope.contact);
      };

      scope.isJitsiAvailable = () => {
        return JitsiProxy.jitsiAvailable;
      };

      const generateMailToLink = () => {
        const template = scope.appConfig.mailTemplate;
        const fullName = scope.contact.displayName;
        const email = scope.email;
        const calls  = XucPhoneState.getCalls();

        const callerNum = _.get(calls.find(call => call.otherDN !== 'anonymous'), 'otherDN');
        const subject = replaceVariables(template.subject, callerNum, fullName);
        const body = replaceVariables(template.body, callerNum, fullName);

        scope.mailToLink = encodeURI(`mailTo:${email}?subject=${subject}&body=${body}`);
      };

      const replaceVariables = (text, callerNum, fullName) => {
        const replace = (text, pattern, value, fallback) => {
          let v = value ? value : `###${$translate.instant(fallback)}###`;
          let r = new RegExp(pattern,"g");
          return text.replace(r, v);
        };

        text = replace(text, '{callernum}', callerNum, 'PLACEHOLDER_NUMBER');
        text = replace(text, '{dstname}', fullName, 'PLACEHOLDER_NAME');
        return text;
      };

      const hasUsername = (contact) => {
        let u = contact.username;
        return !_.isEmpty(u);
      };

      init();

      scope.getStateColor = (status, videoStatus) => {
        if (videoStatus == "Busy") return "user-status-text1";
        return "user-status-text" + status;
      };

      scope.goToChat = (username) => {
        $state.go(scope.appConfig.routing + '.conversation', {remoteParty: username});
      };

      scope.canShowChat = () => {
        return hasUsername(scope.contact);
      };

      scope.canShowVideo = () => {
        return hasUsername(scope.contact);
      };

      scope.canInviteToMeetingRoom = () => {
        if(JitsiProxy.videoIsOngoing()) {
          return hasUsername(scope.contact);
        }
      };

      scope.inviteToMeetingRoom = (username, displayName) => {
        JitsiProxy.inviteToMeetingRoom(username, displayName);
      };

      scope.startPointToPointVideoCall = (username, displayName) => {
        JitsiProxy.startPointToPointVideoCall(username, displayName);
      };

      scope.canShowDirectTransfer = (calls = XucPhoneState.getCalls()) => {
        return (calls.length == 1 && calls.some(c => c.state == XucPhoneState.STATE_ESTABLISHED)) && scope.appConfig.switchboard;
      };

      scope.dropdownStatus = (open, id) => {
        keyNavUtility.stop(open);
        document.getElementById('call-' + id).focus();
      };
      
      scope.canInviteToConference = () => {
        return XucPhoneState.getConferenceOrganizer();
      };

      scope.inviteToConference = (exten) => {
        let conference = XucPhoneState.getConference();
        if (conference != undefined) {
          CtiProxy.conferenceInvite(conference.conferenceNumber, exten, 'User', true);
        }
      };

      scope.generateMeetingShareLink = () => {
        return `${$window.location.origin}${scope.contact.url}`;
      };

      scope.join = () => {
        if(scope.isMeetingRoom()) {
          JitsiProxy.startVideo(scope.contact.contact_id, _isPersonal(scope.contact)).then(
            XucPhoneState.getCallsNotOnHold().forEach(call => {
              CtiProxy.hold(call.uniqueId);
            }) 
          ).catch(
            e => {
              $log.error("Cannot start meeting room", e);
            }
          );
        } 
      };

      scope.dial = (number) => {
        callContext.normalizeDialOrAttTrans(number);
      };

      const _isPersonal = (meetingRoomContact) => {
        return meetingRoomContact.personal ? 'personal' : 'static';
      };

      scope.attendedTransfer = function(dst) {
        CtiProxy.attendedTransfer(XucUtils.normalizePhoneNb(dst));
      };

      scope.directTransfer = function (dst) {
        if (dst) CtiProxy.directTransfer(XucUtils.normalizePhoneNb(dst));
      };

      const getTranslationType = function (type) {
        switch (type) {
        case "meetingroom":
          return "MEETINGROOM_COPIED";
        case "phone":
          return "PHONE_COPIED";
        case "email":
          return "EMAIL_COPIED";
        }
      };

      scope.clipboardPopup = function (kind) {
        toast({
          duration: 3000,
          message: $translate.instant(getTranslationType(kind)),
          className: 'alert-copied',
          position: "center",
          container: '.toast-container'
        });
      };
    }
  };
}
