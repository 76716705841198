import {UserPreferencePreferredDevice} from "xchelper/models/userPreference.model";

export default class UserController {

  constructor ($scope, XucUser, $translate, CtiProxy, $state, forward, BrowserService, UserPreferenceService) {
    $scope.user = {};

    if (BrowserService.browser === 'firefox' && CtiProxy.isUsingWebRtc()) {
      BrowserService.compatibilityAlert();
    }

    XucUser.getUserAsync().then(function(user) {
      $scope.user = user;
    });
    $scope.forwarded_title = "";
    $translate('FORWARDED').then(function(transl) {
      $scope.forwarded_title = transl;
    });
    $translate('FORWARD_LABEL').then(function(transl) {
      $scope.forwarded_label = transl;
    });
    $translate('CLICK_TO_CHANGE').then(function(transl) {
      $scope.click_to_change = transl;
    });
    $translate('NO_ANSWER_ABREV').then(function(transl) {
      $scope.na_abrev = transl;
    });
    $translate('BUSY_ABREV').then(function(transl) {
      $scope.busy_abrev = transl;
    });

    var buildForwardLabel = function(naDest, busyDest) {
      var fwdLabel = $scope.forwarded_label;
      if ($scope.user.naFwdEnabled) {
        fwdLabel = fwdLabel + ' ' + $scope.na_abrev + ' ' + naDest;
      }
      if ($scope.user.busyFwdEnabled) {
        fwdLabel = fwdLabel + ' ' + $scope.busy_abrev + ' ' + busyDest;
      }
      return fwdLabel;
    };

    $scope.getForwardNumberOrDnd = function() {
      return forward.getDestination($scope.user);
    };

    $scope.forwardPopover = function() {
      if (!$scope.user.dndEnabled){
        if ($scope.user.uncFwdEnabled) return ($scope.forwarded_title + ':\n ' + $scope.user.uncFwdDestination);
        if ($scope.user.naFwdEnabled || $scope.user.busyFwdEnabled) {
          return buildForwardLabel($scope.user.naFwdDestination, $scope.user.busyFwdDestination);
        }
      }
      return ($scope.click_to_change);
    };

    $scope.isForwarded = function() {
      return forward.isSet($scope.user);
    };

    $scope.isWebRtcActive = function() {
      return CtiProxy.isUsingWebRtc();
    };

    $scope.getDisplayIcon = function() {
      var line = 'fixed';
      if ($scope.isWebRtcActive()) {
        line = 'webrtc';

        if (CtiProxy.isUsingMobileApp() && !CtiProxy.isUsingUa()) {
          let preferredDevice = UserPreferenceService.getPreferredDevice();
  
          if (preferredDevice === UserPreferencePreferredDevice.MobileApp) {
            line = 'mobile';
          } else if (preferredDevice === UserPreferencePreferredDevice.WebAppAndMobileApp) {
            line += 'mobile';
          }
        }
      }      

      if ($scope.isForwarded()){
        var forwardIcon = forward.getIcon($scope.user) || 'forward_na';
        return line+'_'+forwardIcon;
      }
      return line;
    };

    $scope.$on("userConfigUpdated", function() {
      $scope.user = XucUser.getUser();
    });

    $scope.showMenu = function() {
      $state.go("interface.menu");
    };
  }
}