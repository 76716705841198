export default function callHistoryPartyFormatter() {

  let _toPartyCallsPerDay = function (callsPerDay) {
    let daysWithParentCalls = [];

    for (var day of callsPerDay) {
      let dayWithDetails = createDayWithDetails();
      retrieveDay(dayWithDetails, day);
      let extractedCalls = _extractCalls(day.details);
      let groupedCalls = _groupCallsByParty(extractedCalls);
      storeGroupedCallsIntoDay(dayWithDetails, groupedCalls);
      daysWithParentCalls.push(dayWithDetails);
    }
    return daysWithParentCalls;
  };

  let _extractCalls = function (calls) {

    let extractedCalls = [];

    for (var call of calls) {
      let rebuiltCall = {
        "targetFirstName": "",
        "targetLastName": "",
        "targetNumber": "",
        "duration": "",
        "start": "",
        "status": ""
      };
      let target = setTarget(call);
      extractCall(target, rebuiltCall, call);
      extractedCalls.push(rebuiltCall);
    }

    return extractedCalls;

  };

  let _groupCallsByParty = function (extractedCalls) {
    let partyCall;
    let partyCalls = [];

    if (extractedCalls.length >= 1) {
      partyCall = createPartyCall(extractedCalls[0]);

      for (let call of extractedCalls) {
        if (phoneNumbersAreDifferent(call, partyCall)) {
          addToPartyCalls(partyCall, partyCalls);
          partyCall = createPartyCall(call);
        }
        let callDetail = createCallDetail(call);
        partyCall.callDetailList.push(callDetail);
      }

      addToPartyCalls(partyCall, partyCalls);
    }
    return partyCalls;
  };

  function retrieveDay(dayWithDetails, day) {
    dayWithDetails.day = day.day;
  }

  function phoneNumbersAreDifferent(call, partyCall) {
    return call.targetNumber != partyCall.targetNumber;
  }

  function addToPartyCalls(partyCall, partyCalls) {
    partyCall.childCount = partyCall.callDetailList.length;
    partyCalls.push(partyCall);
  }

  function createCallDetail(call) {
    return {
      "status": call.status,
      "start": call.start,
      "duration": call.duration
    };
  }

  function createPartyCall(call) {
    return {
      "targetFirstName": call.targetFirstName,
      "targetLastName": call.targetLastName,
      "targetNumber": call.targetNumber,
      "callDetailList": []
    };
  }

  function storeGroupedCallsIntoDay(dayWithDetails, groupedCalls) {
    dayWithDetails.details = groupedCalls;
  }

  function createDayWithDetails() {
    return {
      day: '',
      details: []
    };
  }

  function setTarget(currentIndexJson) {
    let currentStatus = currentIndexJson.status;
    switch (currentStatus) {
    case 'emitted':
      return 'dst';
    case 'abandoned':
    case 'answered':
    case 'missed':
      return 'src';
    }
  }

  function extractCall(target, newTemplate, currentIndexJson) {
    target == 'src' ? setSrcProperties(newTemplate, currentIndexJson) : setDstProperties(newTemplate, currentIndexJson);
  }

  function setDstProperties(newTemplate, currentIndexJson) {
    newTemplate.targetFirstName = currentIndexJson.dstFirstName;
    newTemplate.targetLastName = currentIndexJson.dstLastName;
    newTemplate.targetNumber = currentIndexJson.dstNum;
    newTemplate.duration = currentIndexJson.duration;
    newTemplate.start = currentIndexJson.start;
    newTemplate.status = currentIndexJson.status;
    newTemplate.display = false;
  }

  function setSrcProperties(newTemplate, currentIndexJson) {
    newTemplate.targetFirstName = currentIndexJson.srcFirstName;
    newTemplate.targetLastName = currentIndexJson.srcLastName;
    newTemplate.targetNumber = currentIndexJson.srcNum;
    newTemplate.duration = currentIndexJson.duration;
    newTemplate.start = currentIndexJson.start;
    newTemplate.status = currentIndexJson.status;
    newTemplate.display = false;
  }

  return {
    extractCalls: _extractCalls,
    toPartyCallsPerDay: _toPartyCallsPerDay,
    groupCallsByParty: _groupCallsByParty
  };

}