"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class IncomingCall {
    constructor($rootScope, $uibModal, $log, XucPhoneEventListener, XucVideoEventManager, remoteConfiguration, XucLink, webRtcAudio, electronWrapper) {
        this.$rootScope = $rootScope;
        this.$uibModal = $uibModal;
        this.$log = $log;
        this.XucPhoneEventListener = XucPhoneEventListener;
        this.XucVideoEventManager = XucVideoEventManager;
        this.remoteConfiguration = remoteConfiguration;
        this.XucLink = XucLink;
        this.webRtcAudio = webRtcAudio;
        this.electronWrapper = electronWrapper;
        this.timeout = 15000;
        this.timer = undefined;
        this.modalInstance = undefined;
        this.init = () => {
            this.$log.info("Starting incomingCall service");
            this.XucLink.whenLoggedOut().then(this.uninit);
        };
        this.uninit = () => {
            this.$log.info("Unloading incomingCall service");
            this.modalInstance = undefined;
            if (this.timer)
                clearTimeout(this.timer);
            this.webRtcAudio.stopRingtones();
            this.XucLink.whenLogged().then(this.init);
        };
        this.subscribeForEvents = (withPhoneModal) => {
            if (withPhoneModal)
                this.XucPhoneEventListener.addRingingHandler(this.$rootScope, this.onCallRinging);
            this.XucVideoEventManager.subscribeToVideoInviteEvent(this.$rootScope, this.onVideoInvite);
        };
        this.onModalExpiration = () => {
            if (this.modalInstance)
                this.modalInstance.close("timeout");
            this.webRtcAudio.stopRingtones();
            this.modalInstance = undefined;
        };
        this.showModal = (backdrop, number, name, callType, uniqueId, requestId, username, token) => {
            this.modalInstance = this.$uibModal.open({
                templateUrl: 'assets/javascripts/xchelper/services/incomingcall.html',
                size: 'sm',
                backdrop: backdrop,
                controller: 'IncomingCallPopup',
                resolve: {
                    backdrop: function () {
                        return backdrop;
                    },
                    incomingCallNumber: function () {
                        return number;
                    },
                    incomingCallName: function () {
                        return name.trim();
                    },
                    callType: function () {
                        return callType;
                    },
                    uniqueId: function () {
                        return uniqueId;
                    },
                    requestId: function () {
                        return requestId;
                    },
                    username: function () {
                        return username;
                    },
                    token: function () {
                        return token;
                    },
                }
            });
            this.modalInstance.result.catch(() => {
                if (this.timer)
                    clearTimeout(this.timer);
                this.$log.debug("modal closed");
                this.webRtcAudio.stopRingtones();
                this.modalInstance = undefined;
            });
        };
        this.onCallRinging = (event) => {
            if (this.modalInstance) {
                return;
            }
            this.showModal('true', event.otherDN, event.otherDName, event.callType, event.uniqueId);
        };
        this.onVideoInvite = (event) => {
            if (this.modalInstance) {
                this.modalInstance.close("phone modal displayed, dismiss for video invite");
                this.modalInstance = undefined;
            }
            this.timer = setTimeout(this.onModalExpiration, this.timeout);
            if (this.electronWrapper.isWindowToggled())
                this.electronWrapper.toggleWindow(this.electronToggledSize, this.electronDefaultSize);
            this.showModal('static', '', event.displayName, 'video', '', event.requestId, event.username, event.token);
        };
        XucLink.whenLogged().then(this.init);
        this.electronToggledSize = { width: this.electronWrapper.ELECTRON_AGENT_MINI_WIDTH, height: this.electronWrapper.ELECTRON_AGENT_MINI_HEIGHT, minimalist: true };
        this.electronDefaultSize = { width: this.electronWrapper.ELECTRON_AGENT_DEFAULT_WIDTH, height: this.electronWrapper.ELECTRON_AGENT_DEFAULT_HEIGHT, minimalist: false };
        this.subscribeForEvents(!this.remoteConfiguration.isAgent());
    }
}
exports.default = IncomingCall;
