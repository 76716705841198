export default class ConversationController {  

  constructor($scope, $state, $stateParams) {
    $scope.remoteParty = $stateParams.remoteParty;

    $scope.gotoConversationHistory = () => {
      $state.go("interface.conversationHistory");
    };
  }
}
