export default function dragDrop() {

  const _setDataTransfer = (event, data) => {
    event.dataTransfer.setData('text', JSON.stringify(data));
  };

  const _getDataTransfer = (event) => {
    return JSON.parse(event.dataTransfer.getData('text'));
  };

  const _onDragStartEvent = (event) => {
    event.dataTransfer.effectAllowed = 'move';
  };

  const _onDropEvent = (event) => {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) event.stopPropagation();
  };

  return {
    setDataTransfer: _setDataTransfer,
    getDataTransfer: _getDataTransfer,
    onDragStartEvent : _onDragStartEvent,
    onDropEvent: _onDropEvent
  };
}