"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BadgeManagerService = void 0;
class BadgeManagerService {
    constructor($rootScope, electronWrapper, XucChat, UserPreferenceService) {
        this.$rootScope = $rootScope;
        this.electronWrapper = electronWrapper;
        this.XucChat = XucChat;
        this.UserPreferenceService = UserPreferenceService;
        this.hasMissedMessage = false;
        this.hasMissedCall = false;
    }
    updateBadge() {
        if (this.hasMissedMessage || this.hasMissedCall) {
            this.electronWrapper.setTrayIcon('missed');
        }
        else {
            this.electronWrapper.setTrayIcon('default');
        }
    }
    ;
    onMissedMessage(e, counter) {
        this.hasMissedMessage = counter > 0;
        this.updateBadge();
    }
    ;
    onMissedCall(e, counter) {
        this.hasMissedCall = counter > 0;
        this.updateBadge();
    }
    ;
    init() {
        this.$rootScope.$on(this.XucChat.CHAT_UNREAD_MESSAGE, this.onMissedMessage.bind(this));
        this.$rootScope.$on(this.UserPreferenceService.NEW_NB_MISSED_CALLS, this.onMissedCall.bind(this));
    }
    ;
}
exports.BadgeManagerService = BadgeManagerService;
BadgeManagerService.$inject = ["$rootScope", "electronWrapper", "XucChat", "UserPreferenceService"];
