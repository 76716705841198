"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class MobileAppCompatibilityService {
    constructor($window, $rootScope, XucLink, $log, $uibModal, CtiProxy) {
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.XucLink = XucLink;
        this.$log = $log;
        this.$uibModal = $uibModal;
        this.CtiProxy = CtiProxy;
        this.modalInstance = undefined;
        this.init = () => {
            this.$log.info("Starting Mobile app security service");
            this.$rootScope.$on(this.CtiProxy.LINECONFIG_PROCESSED, this.onLineConfigChange.bind(this));
            this.XucLink.whenLoggedOut().then(this.unInit);
        };
        this.unInit = () => {
            this.$log.info("Unloading mobile app security service");
            this.modalInstance = undefined;
            this.XucLink.whenLogged().then(this.init);
        };
        this.XucLink.whenLogged().then(this.init);
    }
    onLineConfigChange() {
        if (this.modalInstance == undefined &&
            this.CtiProxy.isUsingMobileApp() &&
            (this.CtiProxy.isUsingUa() || this.CtiProxy.isUsingCti())) {
            this.modalInstance = this.$uibModal.open({
                templateUrl: 'assets/javascripts/xchelper/services/mobileAppCompatibility.html',
                size: 'sm',
                controller: 'NotificationModalController',
                resolve: {}
            });
            this.modalInstance.result.catch(() => {
                this.$log.debug("modal mobileApp closed");
                this.modalInstance = undefined;
            });
            this.CtiProxy.unregisterMobileApp();
        }
    }
}
exports.default = MobileAppCompatibilityService;
MobileAppCompatibilityService.$inject = ["$window", "$rootScope", "XucLink", "$log", "$uibModal", "CtiProxy"];
