import moment from 'moment';
import _ from 'lodash';

export default function XucPhoneState($rootScope, XucPhoneEventListener, XucLink) {
  var _STATE_DIALING = "Dialing";
  var _STATE_RINGING = "Ringing";
  var _STATE_ESTABLISHED = "Established";
  var _STATE_AVAILABLE = "Available";
  var _STATE_ONHOLD = "OnHold";
  var _STATE_INIT = "Init";

  var _CONF_JOIN = "Join";
  var _CONF_LEAVE = "Leave";
  var _CONF_UPDATE = "Update";

  var _state = _STATE_INIT;
  var _callListInitialized = false;

  var _eventStates = {};
  _eventStates[XucPhoneEventListener.EVENT_DIALING] = _STATE_DIALING;
  _eventStates[XucPhoneEventListener.EVENT_RINGING] = _STATE_RINGING;
  _eventStates[XucPhoneEventListener.EVENT_ESTABLISHED] = _STATE_ESTABLISHED;
  _eventStates[XucPhoneEventListener.EVENT_RELEASED] = _STATE_AVAILABLE;
  _eventStates[XucPhoneEventListener.EVENT_ONHOLD] = _STATE_ONHOLD;

  var _calls = [];
  var _waitingEvents = [];
  var _deviceConference = {calls: [], active: false};
      
  var _getConference = () => {
    let c = _.find(_calls, (call) => {
      return call.conference != undefined;
    });

    if(c) {
      return c.conference;
    }
  };

  var _getConferenceOrganizer = () => {
    let conference = _getConference();
    if(conference) {
      return conference.currentUserRole == 'Organizer';
    } else {
      return false;
    }
  };

  var _getState = function() {
    return _state;
  };

  var _getCalls = function() {
    return _calls;
  };

  var _getCallsOnHold = function() {
    return _getCalls().filter((call) => {
      return call.state === _STATE_ONHOLD;
    });
  };

  var _getCallsNotOnHold = function() {
    return _getCalls().filter((call) => {
      return call.state != _STATE_ONHOLD;
    });
  };

  var _isInCall = function() {
    return _calls.length !== 0;
  };

  var _getDeviceConference = function() {
    return _deviceConference;
  };

  var _dial = function(number) {
    $rootScope.$broadcast("EventShowCallPlaceHolder");
    if(_calls.length === 0) {
      Cti.dial(number);
    } else {
      Cti.attendedTransfer(number);
    }
  };

  var _isPhoneAvailable = function(status) {
    return status === _STATE_AVAILABLE;
  };

  var _isPhoneOffHook = function(status) {
    return status === _STATE_ONHOLD ||
      status === _STATE_DIALING ||
      status === _STATE_ESTABLISHED;
  };

  var _isPhoneRinging = function(status) {
    return status === _STATE_RINGING;
  };

  var _findLineIndex = function(_calls, value) {
    return _.findIndex(_calls, function(o) { return o.linkedId === value; });
  };

  let addStateHandler = (callback) => {
    return $rootScope.$on('PhoneStateEvent', (event, phoneStateEventType) => {
      callback(phoneStateEventType);
    });
  };

  var _unregisterOnPhone;
  var _unregisterOnCurrentCallPhone;

  var _onPhoneEvent = function(event) {
    if (event.eventType == XucPhoneEventListener.EVENT_FAILURE) return;

    if(!event.uniqueId && !event.linkedId) {
      if(event.eventType === XucPhoneEventListener.EVENT_RELEASED) {
        _.remove(_calls, function(call) {
          return call.userData.SIPCALLID === event.userData.SIPCALLID;
        });
        return;
      }
    } 

    var lineIndex = _findLineIndex(_calls, event.linkedId);
    if(lineIndex < 0 && event.eventType === XucPhoneEventListener.EVENT_RELEASED) {
      lineIndex = _findLineIndex(_calls, event.uniqueId);
    }
    if (lineIndex < 0) {
      lineIndex = _calls.length;
      _calls[lineIndex] = { linkedId: event.linkedId};
    }

    _calls[lineIndex].state = _eventToState(event.eventType);
    _calls[lineIndex].uniqueId = event.uniqueId;
    _calls[lineIndex].DN = event.DN;
    _calls[lineIndex].direction = event.callDirection;
    _calls[lineIndex].acd = event.queueName ? true : false;
    _calls[lineIndex].mediaType = event.callType;

    if(typeof(event.otherDN) !== "undefined" && event.otherDN !== "") {
      _calls[lineIndex].otherDN = event.otherDN;
    }
    if(typeof(event.otherDName) !== "undefined" && event.otherDName !== "") {
      _calls[lineIndex].otherDName = event.otherDName;
    }
    if(typeof(event.queueName) !== "undefined" && event.queueName !== "") {
      _calls[lineIndex].queueName = event.queueName;
    }

    _calls[lineIndex].userData = event.userData;
    if(typeof(_calls[lineIndex].startTime) === "undefined" && event.eventType === XucPhoneEventListener.EVENT_ESTABLISHED) {
      _calls[lineIndex].startTime = Date.now();
    }
    if(typeof(_calls[lineIndex].username) === "undefined" && event.username !== "") {
      _calls[lineIndex].username = event.username;
    }

    if(event.eventType === XucPhoneEventListener.EVENT_RELEASED) {
      _.remove(_calls, function(call) {
        return call.uniqueId === event.uniqueId || call.linkedId === event.linkedId;
      });
    }

    if(_calls.length > 1 && _.every(_calls, {state: _STATE_ESTABLISHED})) {
      if(!_deviceConference.active) {
        _deviceConference.startTime = Date.now();
        _deviceConference.active = true;
      }
      _.each(_calls, function(call) {
        if(!_.find(_deviceConference.calls, {uniqueId: call.uniqueId})) {
          _deviceConference.calls.push(call);
        }
      });
    } else {
      _deviceConference.active = false;
      _deviceConference.calls.length = 0;
    }

    let _previousState = _state;
    _state = _.reduce(_calls, function(result, call) {
      return call.state;
    }, _STATE_AVAILABLE);
    if (_previousState != _state){
      $rootScope.$emit('PhoneStateEvent', _state);
    }

    _callListInitialized = true;

    while(_waitingEvents.length != 0){
      _processConferenceEvent(_waitingEvents.shift());
    }
  };

  var _onCurrentCallsPhoneEvents = function(events) {
    angular.forEach(events, function(event) {
      _onPhoneEvent(event);
    });
  };

  var _isConferenceAndIsMuted = function(uniqueId){
    let indexCall = _findLineIndex(_calls, uniqueId);
    if (indexCall === -1) {
      return false;
    }
    
    if (!_calls[indexCall].conference) {
      return false;
    }

    let myParticipant = _calls[indexCall].conference.participants.filter(p => p.isMe);
    if (myParticipant.length === 1){
      return myParticipant[0].isMuted;
    }

    return false ;
  };

  var _eventToState = function(state) {
    if(typeof(_eventStates[state]) !== "undefined") {
      return _eventStates[state];
    } else {
      return null;
    }
  };

  var _processConferenceEvent = function(event) {
    var lineIndex = _.findIndex(_calls, function(o) { return o.uniqueId === event.uniqueId; });

    if (lineIndex >= 0) {
      if(event.eventType === _CONF_LEAVE) {
        delete _calls[lineIndex].conference;
      } else {
        var participants = _.map(event.participants, function(item) {
          item.startTime = moment().add(-(item.since), 'seconds').toDate();
          return item;
        });
        var isMeAndOrganizer = _.filter(participants, {'isMe': true, 'role': 'Organizer'}).length > 0;

        _calls[lineIndex].conference = {
          conferenceName: event.conferenceName,
          conferenceNumber: event.conferenceNumber,
          participants: participants,
          since: event.since,
          currentUserRole: isMeAndOrganizer ? 'Organizer':'User',
          startTime: moment().add(-(event.since), 'seconds').toDate()
        };
      }
    }
  };

  var _onCtiConferenceEvent = function(event) {
    if(_state != _STATE_INIT && event.eventType != _CONF_JOIN){      
      _processConferenceEvent(event);
    }
    else if(_callListInitialized === true){
      _processConferenceEvent(event);
    }
    else{
      _waitingEvents.push(event);
    }
  };
  
  const _setOrganizerRoleToCurrentUser = (lineIndex, participant) => {
    if (_calls[lineIndex].conference.currentUserRole != 'Organizer' &&
      participant.isMe &&
      participant.role == 'Organizer') {
      _calls[lineIndex].conference.currentUserRole = 'Organizer';
    }
  };

  var _addConferenceParticipant = function(lineIndex, event) {
    var cleanedEvent = _.omit(event, ['eventType', 'uniqueId', 'phoneNumber']);
    var newParticipant = { startTime: moment().add(-(event.since), 'seconds').toDate() };
    _.merge(newParticipant, cleanedEvent);
    _calls[lineIndex].conference.participants.push(newParticipant);
    _setOrganizerRoleToCurrentUser(lineIndex, newParticipant);
  };

  var _updateConferenceParticipant = function (lineIndex, event) {
    let cleanedEvent = _.omit(event, ['eventType', 'uniqueId', 'conferenceNumber', 'phoneNumber']);
    let participant = _calls[lineIndex].conference.participants.find(participant => participant.index == event.index);
    if (participant) {
      Object.assign(participant, cleanedEvent);
      _setOrganizerRoleToCurrentUser(lineIndex, participant);
    }
  };

  var _removeConferenceParticipant = function(lineIndex, index) {
    _.remove(_calls[lineIndex].conference.participants, function(n) {
      return n.index === index;
    });
  };

  var _onCtiConferenceParticipantEvent = function(event) {
    var lineIndex = _.findIndex(_calls, function(o) { return o.uniqueId === event.uniqueId; });

    if (lineIndex >= 0 && _.isObject(_calls[lineIndex].conference)) {
      if(event.eventType === _CONF_JOIN) {
        _addConferenceParticipant(lineIndex, event);
      } else if (event.eventType === _CONF_UPDATE) {
        _updateConferenceParticipant(lineIndex, event);
      } else {
        _removeConferenceParticipant(lineIndex, event.index);
      }
    }
  };

  var _unInit = function() {
    _calls = [];
    _deviceConference = {calls: [], active: false};
    if (typeof _unregisterOnPhone === "function") {
      _unregisterOnPhone();
      _unregisterOnPhone = undefined;
    }
    if (typeof _unregisterOnCurrentCallPhone === "function") {
      _unregisterOnCurrentCallPhone ();
      _unregisterOnCurrentCallPhone = undefined;
    }
    Cti.unsetHandler(Cti.MessageType.CONFERENCEEVENT, _onCtiConferenceEvent);
    Cti.unsetHandler(Cti.MessageType.CONFERENCEPARTICIPANTEVENT, _onCtiConferenceParticipantEvent);
    XucLink.whenLogged().then(_init);
  };

  var _init = function() {
    if (_unregisterOnPhone === undefined) {
      _unregisterOnPhone = XucPhoneEventListener.addHandlerCustom(_onPhoneEvent);
    }
    if (_unregisterOnCurrentCallPhone === undefined) {
      _unregisterOnCurrentCallPhone =
        XucPhoneEventListener.addHandlerCustom(_onCurrentCallsPhoneEvents, 'CurrentCallsEvents');
    }

    _callListInitialized = false;

    Cti.setHandler(Cti.MessageType.CONFERENCEEVENT, _onCtiConferenceEvent);
    Cti.setHandler(Cti.MessageType.CONFERENCEPARTICIPANTEVENT, _onCtiConferenceParticipantEvent);

    XucLink.whenLoggedOut().then(_unInit);
    XucPhoneEventListener.requestPhoneEventsForCurrentCalls();
  };

  XucLink.whenLogged().then(_init);

  return {
    STATE_DIALING: _STATE_DIALING,
    STATE_RINGING: _STATE_RINGING,
    STATE_ESTABLISHED: _STATE_ESTABLISHED,
    STATE_AVAILABLE: _STATE_AVAILABLE,
    STATE_ONHOLD: _STATE_ONHOLD,
    getState: _getState,
    getCalls: _getCalls,
    getCallsOnHold : _getCallsOnHold,
    getCallsNotOnHold : _getCallsNotOnHold,
    isInCall: _isInCall,
    getDeviceConference: _getDeviceConference,
    dial: _dial,
    isPhoneAvailable: _isPhoneAvailable,
    isPhoneOffHook: _isPhoneOffHook,
    isPhoneRinging: _isPhoneRinging,
    isConferenceAndIsMuted: _isConferenceAndIsMuted,
    getConference: _getConference,
    addStateHandler: addStateHandler,
    getConferenceOrganizer: _getConferenceOrganizer
  };
}
