export default function webRtcAudio($rootScope, $log, $q, XucPhoneEventListener, xcHelperPreferences, XucPhoneState, XucVideoEventManager, JitsiProxy) {

  var getUserRingtone = () => {
    var userRingtone = JSON.parse(window.localStorage.getItem('user_ringtone'));
    return userRingtone ? userRingtone.file : 'incoming_call.mp3';
  };

  var isEnabled = false;
  var ringingAudioElem = '<audio id="webrtc_audio_ringing" preload="auto" loop="loop" src="assets/audio/pratix/ringing.mp3"></audio>';
  var incomingCallAudioElem = `<audio id="webrtc_audio_incoming_call" preload="auto" loop="loop" src="assets/audio/pratix/${getUserRingtone()}"></audio>`;
  var incomingSecondCallAudioElem = '<audio id="webrtc_audio_incoming_second_call" preload="auto" loop="loop" src="assets/audio/pratix/incoming_second_call.mp3"></audio>';
  var hangupCallAudioElem = '<audio id="webrtc_audio_hangup_call" preload="auto" src="assets/audio/pratix/hangup_call.mp3"></audio>';
  var incomingCallAudio = null;
  var incomingSecondCallAudio = null;
  var ringingAudio = null;
  var hangupCallAudio = null;
  var remoteMediaAddedHandlerUnsubscribe = null;

  var playPromise;

  var startAudio = function(element) {
    element.currentTime = 0;
    playPromise = element.play();
  };

  var stopAudio = function(element) {
    if (playPromise !== undefined) {
      playPromise.then(function() {
        stopAudioImpl(element);
      }).catch(function() {
        stopAudioImpl(element);
      });
    } else {
      stopAudioImpl(element);
    }
  };

  var stopAudioImpl = function(element) {
    element.pause();
    element.currentTime = 0;
    playPromise = undefined;
  };

  var isOriginate = function(event) {
    return event && event.userData && event.userData.XUC_CALLTYPE == "Originate";
  };

  var onPhoneEvent = function(event) {
    if (!isEnabled) {
      return;
    }

    switch (event.eventType) {
    case 'EventDialing':
      stopAudio(incomingCallAudio);
      if (isOriginate(event)) stopAudio(ringingAudio);
      else startAudio(ringingAudio);
      break;
    case 'EventRinging':
      _startRinging();
      break;
    case 'EventReleased':
      startAudio(hangupCallAudio);
      stopRingtones();
      break;
    default:
      stopRingtones();
    }
  };

  var stopRingtones = function() {
    stopAudio(ringingAudio);
    stopAudio(incomingCallAudio);
    stopAudio(incomingSecondCallAudio);
  };

  var enable = function() {
    isEnabled = true;
    if (remoteMediaAddedHandlerUnsubscribe === null) {
      remoteMediaAddedHandlerUnsubscribe = _addHandleRemoteMediaAdded();
    }
  };

  var disable = function() {
    if(isEnabled) {
      stopRingtones();
      isEnabled = false;
      remoteMediaAddedHandlerUnsubscribe();
      remoteMediaAddedHandlerUnsubscribe = null;
    }
  };

  var init = function() {
    var _appendElem = function(elem) { angular.element(document.body).append(elem); };
    _appendElem(ringingAudioElem);
    _appendElem(incomingCallAudioElem);
    _appendElem(incomingSecondCallAudioElem);
    _appendElem(hangupCallAudioElem);

    var _findElem= function(elem) {
      return document.getElementById(elem);
    };
    ringingAudio = _findElem('webrtc_audio_ringing');
    incomingCallAudio = _findElem('webrtc_audio_incoming_call');
    incomingSecondCallAudio = _findElem('webrtc_audio_incoming_second_call');
    hangupCallAudio = _findElem('webrtc_audio_hangup_call');

    if (!ringingAudio || !incomingCallAudio || !incomingSecondCallAudio || !hangupCallAudio) {
      $log.error('Unable to inject audio elements');
    }
    XucPhoneEventListener.addHandlerCustom(onPhoneEvent);
    _changeRingingDevice(xcHelperPreferences.getRingingDeviceId())
      .catch((err) => {
        $log.debug(err);
      });

    XucVideoEventManager.subscribeToVideoInviteEvent($rootScope, _startRinging);
  };

  var _addHandleRemoteMediaAdded = () => {
    return xc_webrtc.setHandler(xc_webrtc.MessageType.INCOMING, function(event) {
      if(event.type === xc_webrtc.Incoming.REMOTE_MEDIA_ADDED) {
        stopRingtones();
      }
    });
  };

  var _changeRingtone = (ringtone) => {
    incomingCallAudio.remove();
    var incomingCallAudioElem = `<audio id="webrtc_audio_incoming_call" preload="auto" loop="loop" src="assets/audio/pratix/${ringtone.file}"></audio>`;
    angular.element(document.body).append(incomingCallAudioElem);
    incomingCallAudio = document.getElementById('webrtc_audio_incoming_call');
  };

  var _changeRingingDevice = (id) => {
    if(incomingCallAudio.setSinkId) {
      return incomingCallAudio.setSinkId(id)
        .catch((err) => {
          $log.debug(err + '. Using default ringing device instead');
        });
    } else {
      return $q.reject("Browser doesn't support changing audio output");
    }

  };

  var _isBusy = () => {
    return XucPhoneState.isPhoneOffHook(XucPhoneState.getState()) || JitsiProxy.videoIsOngoing();
  };

  var _startRinging = () => {
    _isBusy() ?
      startAudio(incomingSecondCallAudio) :
      startAudio(incomingCallAudio);
    stopAudio(ringingAudio);
  };

  init();

  return {
    enable: function() { enable(); },
    disable: function() { disable(); },
    isEnabled: function() { return isEnabled; },
    changeRingingDevice: _changeRingingDevice,
    changeRingtone: _changeRingtone,
    startRinging: _startRinging,
    stopRingtones: function() { stopRingtones(); }
  };
}
