export default class deleteMobileAppModal {

  constructor($scope, $uibModalInstance, CtiProxy) {

    $scope.ok = function () {
      $uibModalInstance.close();
      CtiProxy.unregisterMobileApp();
    };

    $scope.cancel = function () {
      $uibModalInstance.close();
    };
  }
}