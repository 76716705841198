export default class ConfirmationModalController {

  constructor($uibModalInstance, params) {
    this.params = params;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModalInstance.result.then(() => {}, () => {
      this.close();
    });
  }

  close() {
    this.$uibModalInstance.close();
  }
}