import _ from 'lodash';

export default class ContactsController {

  constructor($rootScope, $scope, $state, $stateParams, XucDirectory, callContext, JitsiProxy,XucPhoneState, XucPhoneEventListener, $translate,applicationConfiguration, CtiProxy, $log,toast, $window) {

    $scope.$on('searchResultUpdated', function() {
      $scope.searchResult = _.each(XucDirectory.getSearchResult(), function(contact) {
        $scope.buildContact(contact);
      });
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('favoritesUpdated', function() {
      $scope.favorites = _.each(XucDirectory.getFavorites(), function(contact) {
        $scope.buildContact(contact);
      });
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.buildContact = (contact) => {
      contact.displayName = contact.entry[0];
      if (contact.displayName.length > 0) contact.initials = contact.displayName.split(' ').map(part => getInitial(part));
      if (contact.initials.length > 0) contact.initials = contact.initials.slice(0, 4).join(" ");
      contact.toggleDetails = false;
      contact = $scope.buildPhoneNumbers(contact);
      return contact;
    };

    $scope.inviteToConference = (exten, $event) => {
      $event.stopPropagation();
      let conference = XucPhoneState.getConference();
      if (conference != undefined) {
        CtiProxy.conferenceInvite(conference.conferenceNumber, exten, 'User', true);
      }
    };

    $scope.isEmpty = function(field) {
      if (typeof field != 'undefined') {
        if (field.length > 0) {
          return true;
        }
      }
      return false;
    };

    $scope.$on('DirectoryLookUp', function(event, term) {
      $scope.search(term);
    });

    $scope.search = function(term) {
      Cti.directoryLookUp(term);
      $scope.showSearch = true;
    };

    $scope.getFavorites = function() {
      Cti.getFavorites();
    };

    $scope.addFavorite = function(contact, $event) {
      $event.stopPropagation();
      Cti.addFavorite(contact.contact_id, contact.source);
    };

    $scope.removeFavorite = function(contact, $event) {
      $event.stopPropagation();
      Cti.removeFavorite(contact.contact_id, contact.source);
    };

    $scope.init = function() {
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
      if ($stateParams.showFavorites === false) {
        $scope.showSearch = true;
      } else {
        $scope.getFavorites();
        $scope.showSearch = false;
      }

      if (typeof($stateParams.search) != 'undefined') {
        $scope.search($stateParams.search);
      }
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
    };

    $scope.isCallable = function(contact) {
      return _.some([1, 2, 3, 5], function(index) {
        return !_.isEmpty(contact.entry[index]);
      }) || callContext.isMeetingRoom(contact);
    };

    $scope.isMeetingRoom = function (contact) {
      return callContext.isMeetingRoom(contact);
    };

    $scope.startPointToPointVideoCall = (username, displayName, $event) => {
      $event.stopPropagation();
      JitsiProxy.startPointToPointVideoCall(username, displayName);
    };

    const hasUsername = (contact) => {
      if (contact !== undefined) {
        return !_.isEmpty(contact.username);
      }
      return false;
    };

    $scope.goToChat = (username, $event) => {
      $event.stopPropagation();
      $state.go($scope.appConfig.routing + '.conversation', {remoteParty: username});
    };

    $scope.canShowChat = (contact) => {
      return hasUsername(contact);
    };
    $scope.canShowVideo = (contact) => {
      return hasUsername(contact);
    };

    $scope.buildPhoneNumbers = (contact) => {
      contact.phoneNumbers = [];
      if(_.isObject(contact)) {
        _.forEach([1, 2, 3], (index) => {
          if (!_.isEmpty(contact.entry[index])) {
            contact.phoneNumbers.push(contact.entry[index]);
          }
        });

        if (!_.isEmpty(contact.entry[5])) {
          contact.email = contact.entry[5];
          contact.mailToLink = generateMailToLink(contact);
          XucPhoneEventListener.addEstablishedHandler($scope, generateMailToLink);
          XucPhoneEventListener.addReleasedHandler($scope, generateMailToLink);
        }
      }
      return contact;
    };

    $scope.canInviteToMeetingRoom = (contact) => {
      if(JitsiProxy.videoIsOngoing()) {
        return hasUsername(contact);
      }
    };

    $scope.inviteToMeetingRoom = (username, displayName, $event) => {
      $event.stopPropagation();
      JitsiProxy.inviteToMeetingRoom(username, displayName);
    };


    const _isPersonal = (meetingRoomContact) => {
      return meetingRoomContact.personal ? 'personal' : 'static';
    };

    $scope.join = (contact, $event) => {
      $event.stopPropagation();
      if($scope.isMeetingRoom(contact)) {
        JitsiProxy.startVideo(contact.contact_id, _isPersonal(contact)).then(
          XucPhoneState.getCallsNotOnHold().forEach(call => {
            CtiProxy.hold(call.uniqueId);
          })
        ).catch(
          e => {
            $log.error("Cannot start meeting room", e);
          }
        );
      }
    };

    $scope.dial = (number) => {
      callContext.normalizeDialOrAttTrans(number);
    };

    $scope.isJitsiAvailable = () => {
      return JitsiProxy.jitsiAvailable;
    };

    $scope.makeACall = (contact, $event, phone = undefined) => {
      $event.stopPropagation();
      if ($scope.isMeetingRoom(contact)) {
        return $scope.dial('**' + contact.phoneNumbers[0]);
      }
      return $scope.dial(phone || contact.phoneNumbers[0]);
    };

    $scope.makeAVideoCall = (contact, $event) => {
      $event.stopPropagation();
      if ($scope.isMeetingRoom(contact)) {
        return $scope.join(contact);
      }
      return $scope.startPointToPointVideoCall(contact.username, contact.displayName, $event);
    };

    const getTranslationType = function (type) {
      switch (type) {
      case "meetingroom":
        return "MEETINGROOM_COPIED";
      case "phone":
        return "PHONE_COPIED";
      case "email":
        return "EMAIL_COPIED";
      }
    };

    $scope.clipboardPopup = function (kind, $event) {
      $event.stopPropagation();
      toast({
        duration: 3000,
        message: $translate.instant(getTranslationType(kind)),
        className: 'alert-copied',
        position: "center",
        container: '.toast-container'
      });
    };

    $scope.generateMeetingShareLink = (contact) => {
      return `${$window.location.origin}${contact.url}`;
    };

    const getInitial = (name) => {
      if (name && name.length > 0) return name[0];
      else return "";
    };

    const generateMailToLink = (contact) => {
      const template = $scope.appConfig.mailTemplate;
      const fullName = contact.displayName;
      const email = contact.email;
      const calls  = XucPhoneState.getCalls();

      const callerNum = _.get(calls.find(call => call.otherDN !== 'anonymous'), 'otherDN');
      const subject = replaceVariables(template.subject, callerNum, fullName);
      const body = replaceVariables(template.body, callerNum, fullName);

      return encodeURI(`mailTo:${email}?subject=${subject}&body=${body}`);
    };

    const replaceVariables = (text, callerNum, fullName) => {
      const replace = (text, pattern, value, fallback) => {
        let v = value ? value : `###${$translate.instant(fallback)}###`;
        let r = new RegExp(pattern,"g");
        return text.replace(r, v);
      };

      text = replace(text, '{callernum}', callerNum, 'PLACEHOLDER_NUMBER');
      text = replace(text, '{dstname}', fullName, 'PLACEHOLDER_NAME');
      return text;
    };

    $scope.navigateToContactEditor = (contact) => {
      if ($scope.isMeetingRoom(contact)) {
        return `interface.meetingRooms({id: '${contact.contact_id}'})`;
      }
      return `interface.personalContact({id: '${contact.contact_id}'})`;
    };

    $scope.hasDetail = (contact) => {
      if (contact.phoneNumbers.length > 0) {
        return true;
      }
      return !_.isNil(contact.email) && contact.email !== '';
    };

    $scope.hasChevron = (contact) => {
      return ($scope.canInviteToConference() 
      || contact.phoneNumbers.length > 1
      || (contact.phoneNumbers.length === 1 && !_.isNil(contact.email) && contact.email !== '')); 
    };

    $scope.getChevronClass = (contact) => {
      return  contact.toggleDetails ? "fa-chevron-up" : "fa-chevron-down";
    };

    $scope.callButtonsDisplay = (contact) => {
      return  !$scope.hasChevron(contact) ? "call-buttons-margin" : "";
    };

    $scope.canInviteToConference = () => {
      return XucPhoneState.getConferenceOrganizer();
    };

    $scope.userWithCallIcon = (contact) => {
      return !$scope.canInviteToConference()
      && !contact.toggleDetails 
      && $scope.isCallable(contact) 
      && !$scope.isMeetingRoom(contact)
      && !$scope.hasJustEmail(contact);
    };

    $scope.meetingRoomWithCallIcon = (contact) => {
      return !$scope.canInviteToConference() 
      && !contact.toggleDetails 
      && $scope.isMeetingRoom(contact) 
      && $scope.isJitsiAvailable();
    };

    $scope.userWithInviteToConferenceIcon = (contact) => {
      return $scope.canInviteToConference() && !contact.toggleDetails && $scope.isCallable(contact) && !$scope.isMeetingRoom(contact);
    };

    $scope.meetingRoomWithInviteToConferenceIcon = (contact) => {
      return $scope.canInviteToConference() && !contact.toggleDetails && $scope.isMeetingRoom(contact) && $scope.isJitsiAvailable();
    };
    
    $scope.hasJustEmail = (contact) => {
      return contact.phoneNumbers.length === 0 
      && !_.isNil(contact.email) 
      && contact.email !== ''
      && !contact.toggleDetails;
    };

    $scope.init();
  }
}
