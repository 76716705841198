export default function indeterminate(){
  return {
    restrict: 'A',

    link(scope, elem, attr) {
      var watcher = scope.$watch(attr.indeterminate, (value) => {
        elem[0].indeterminate = value;
      });

      scope.$on('$destroy', () => {
        watcher();
      });
    }
  };
}