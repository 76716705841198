export default function keyNavUtility(focus) {

  let stop = false;
  let index = -1;
  let storedElemId;

  const _goUp = (index, maxIndex) => {
    if (maxIndex) {
      if (index == 0) {
        return index = maxIndex - 1;
      } else if (index == -1) {
        return index = maxIndex -1;
      } else {
        return index -= 1; 
      }
    }  
  };

  const _goDown = (index, maxIndex) => {
    if (maxIndex) return index == maxIndex - 1 ? index = 0 : index += 1; 
  };

  const _move = (direction, maxIndex, elemId) => {
    if (elemId != storedElemId) {
      storedElemId = elemId;
      _resetIndex();
    }
    switch (direction) {
    case 'up':
      index = _goUp(index, maxIndex);
      break;
    case 'down':
      index = _goDown(index, maxIndex);
      break;
    }
    focus(elemId + index);  
  };
  
  const _resetIndex = () => {
    index = -1;
    stop = false;
  };

  const _action = (func) => {
    if (index != -1) {
      func({'index': index});
    }
  };

  const _stop = (value) => {
    stop = value;
  };

  const _isStopped = () => {
    return stop;
  };

  return {
    goUp: _goUp,
    goDown: _goDown,
    move: _move,
    resetIndex: _resetIndex,
    action: _action,
    stop: _stop,
    isStopped: _isStopped
  };
}
