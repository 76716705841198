export default function errorModal($uibModal) {

  let modalInstance;
  let isOpen = false;

  let _showErrorModal = function (errorMessage, showSwitchModal = false) {
    if (!modalInstance || !isOpen) {
      modalInstance =  $uibModal.open({
        templateUrl: 'assets/javascripts/xchelper/services/errorModal.html',
        size: 'sm',
        windowClass: 'fatal-error',
        controller: 'FatalErrorController',
        resolve: {
          errorMessage: function () {
            return errorMessage;
          },
          showSwitchModal: function () {
            return showSwitchModal;
          }
        }
      });
      modalInstance.opened.then(()=>{isOpen = true;});
      modalInstance.closed.then(()=>{isOpen = false;});
    }
    return modalInstance;
  };

  return {
    showErrorModal: _showErrorModal
  };
}
