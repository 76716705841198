import { LoginHelper } from "./loginForm.helper";

var login = angular.module('xcLogin');
login.directive('loginForm', function(XucLink, $translate, $log, $timeout, $window, $q, remoteConfiguration, XucThirdPartyLoginUrl, $location){
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xclogin/directives/loginForm.html',
    scope: {
      requirePhoneNumber: "=",
      title:"@",
      onLogin: "&",
      errorCode: "=",
      errorParam: "=",
      hostAndPort: "=",
      useSso: "=",
      casServerUrl: "=",
      casLogoutEnable: "=",
      openidServerUrl: "=",
      openidClientId: "=",
      openidLogoutEnable: "="
    },
    link: function(scope) {
      scope.error = null;
      scope.username = "";
      scope.password = "";
      scope.phoneNumber = XucLink.parseUrlParameters($location.url()).phoneNumber || XucLink.parseUrlParameters($location.absUrl()).phoneNumber;
      scope.autoLogin = false;
      scope.requireCredentials = true;
      var defaultAutoLoginTimeout = 5;
      var retryAutoLoginTimeout = defaultAutoLoginTimeout;
      scope.retryAutoLoginDelay = retryAutoLoginTimeout;
      scope.retryAutoLogin = false;
      scope.translateData = { retryAutoLoginDelay: scope.retryAutoLoginDelay };

      let loginConf = {
        useSso: scope.useSso,
        casServerUrl: scope.casServerUrl,
        casLogoutEnable: scope.casLogoutEnable,
        openidServerUrl: scope.openidServerUrl,
        openidClientId: scope.openidClientId,
        requirePhoneNumber: scope.requirePhoneNumber,
        openidLogoutEnable: scope.openidLogoutEnable
      };

      
      scope.ctrl = new LoginHelper(loginConf, XucLink, $timeout, $log, $window, scope.errorCode);
      // eslint-disable-next-line no-unused-vars
      let xucThirdPartyLoginUrl = XucThirdPartyLoginUrl;

      remoteConfiguration
        .getInt(remoteConfiguration.LOGIN_TIMEOUT_MS)
        .then((timeout) => {
          XucLink.setLoginTimeoutMs(timeout);
        });

      if(Cti.webSocket) {
        Cti.close();
      }

      scope.login = function() {
        if(scope.ctrl.requireCredentials) {
          scope.ctrl.login(scope.username, scope.password, scope.phoneNumber);
        } else {
          scope.ctrl.clearLastError();
          scope.ctrl.autoLogin(scope.phoneNumber);
        }
      };

      var _loginAndRedirect = function(event) {
        if(event.name == "ctiLoggedOn") {
          scope.error = null;
          scope.onLogin();
        }
      };

      scope.retryLogin = function() {
        scope.ctrl.clearLastError();
        scope.ctrl.autoLogin(scope.phoneNumber);
      };

      scope.showVersion = function() {
        return $window.appVersion;
      };
      
      scope.$on('ctiLoggedOn', _loginAndRedirect);
      $log.info("Host : " + scope.hostAndPort);
      
      if (angular.isDefined(scope.hostAndPort)) {
        XucLink.setHostAndPort(scope.hostAndPort);
      }
      XucLink.setRedirectToHomeUrl(false);
      if(!loginConf.requirePhoneNumber || (scope.phoneNumber != undefined && scope.phoneNumber.length > 0)) {
        scope.ctrl.autoLogin(scope.phoneNumber);
      }
    }
  };
});
