export default function keyboard($rootScope, $uibModal) {

  var _show = () => {
    return $uibModal.open({
      templateUrl: 'assets/javascripts/xchelper/services/keyboard.html',
      controller: 'KeyboardPopup',
      windowClass: 'phone-overlay',
    });
  };

  return {show: _show};

}
