export default class CallMgtMenuController {

  constructor($uibModal, $scope, XucUser, XucUtils) {
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.XucUser = XucUser;
    this.XucUtils = XucUtils;
  }

  showForward () {
    this.$uibModal.open({
      templateUrl: 'callMgtDialog.html',
      windowClass: 'callMgtModal',
      size: 'sm',
      controller: 'CallMgtModalController as ctrl',
      resolve: {
        XucUser: this.XucUser
      }
    });
  }
}