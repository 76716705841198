"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeetingRoom = void 0;
class HTTPAuthorizationHeader {
    constructor(Authorization) {
        this.Authorization = Authorization;
    }
}
class HTTPHeaders {
    constructor(timeout, headers) {
        this.timeout = timeout;
        this.headers = headers;
    }
}
class MeetingRoom {
    constructor(id, displayName, userPin, number, uuid) {
        this.id = id;
        this.displayName = displayName;
        this.userPin = userPin;
        this.number = number;
        this.uuid = uuid;
    }
}
exports.MeetingRoom = MeetingRoom;
class MeetingRoomService {
    constructor($http, XucLink) {
        this.$http = $http;
        this.XucLink = XucLink;
        this.timeout = 5000;
        this.getToken = (func) => {
            return this.XucLink.whenLogged().then((user) => {
                let headers = new HTTPHeaders(this.timeout, new HTTPAuthorizationHeader(`Bearer ${user.token}`));
                return func(headers);
            });
        };
        this.getUrl = (id) => {
            let param = id ? "/" + id : "";
            return this.XucLink.getServerUrl("http") + "/xuc/api/2.0/config/meetingrooms/personal" + param;
        };
        this.add = (mr) => {
            let apiCall = (headers) => {
                return this.$http.post(this.getUrl(), mr, headers);
            };
            return this.getToken(apiCall);
        };
        this.get = (id) => {
            let apiCall = (headers) => {
                return this.$http.get(this.getUrl(id), headers);
            };
            return this.getToken(apiCall);
        };
        this.update = (mr) => {
            let apiCall = (headers) => {
                return this.$http.put(this.getUrl(), mr, headers);
            };
            return this.getToken(apiCall);
        };
        this.remove = (id) => {
            let apiCall = (headers) => {
                return this.$http.delete(this.getUrl(id), headers);
            };
            return this.getToken(apiCall);
        };
    }
}
exports.default = MeetingRoomService;
