export default function validPhoneNumber(XucUtils){
  return {
    require: 'ngModel',
    link: function(scope, elem, attr, ngModel) {
      ngModel.$parsers.unshift(function (input) {
        var normalizedValue = XucUtils.normalizePhoneNb(input);
        if (XucUtils.isaPhoneNb(normalizedValue)) {
          ngModel.$setValidity("validPhoneNumber", true);
          return input;
        }
        else {
          ngModel.$setValidity("validPhoneNumber", false);
        }
      });
    }
  };
}
