import angular from 'angular';
import uibootstrap from 'angular-ui-bootstrap';
import uirouter from '@uirouter/angularjs';
import angulartranslate from 'angular-translate';
import ngclipboard from 'ngclipboard';
import uiselect from 'ui-select';
import 'ui-select/dist/select.css';
import localStorageService from 'angular-local-storage';
import 'd3';
import scroll from 'angularjs-scroll-glue';
import 'angular-md5';
import ngcookies from 'angular-cookies';

import config from './helper.config';
import countBadge from './directives/countBadge.directive.js';
import dateInput from './directives/dateInput.directive.js';
import longText from './directives/longText.directive.js';
import fixedHeader from './directives/fixedTableHeader.directive.js';
import validPhoneNumber from './directives/validPhoneNumber.directive.js';
import elapsedTimeFrom from './directives/elapsedTimeFrom.directive.js';
import scrollText from './directives/scrollText.directive.js';
import callActionDropdown from './directives/callActionDropdown.directive';
import indeterminate from './directives/checkboxIndeterminate.directive.js';
import fileInput from './directives/fileInput.directive.js';
import donutChart from './directives/donutChart.directive.js';
import barChart from './directives/barChart.directive.js';
import audioConfiguration from './directives/audioConfiguration.directive';
import ringtoneConfiguration from './directives/ringtoneConfiguration.directive';
import failedDestination from './directives/failedDestination.directive.js';
import isPhoneNumber from './directives/isPhoneNumber.directive.js';
import keyNavigation from './directives/keyNavigation.directive';
import containerResizable from './directives/containerResizable.directive';
import refreshWarning from './directives/refreshWarning.directive';
import callLister from './directives/callLister.directive';
import jitsiVideo from './directives/jitsiVideo.directive';
import debounce from './services/debounce.factory.js';
import callHistoryPartyFormatter from './services/callHistoryPartyFormatter.service';
import plantronics from './services/plantronics.service';
import headset from './services/headset.service';
import ExternalEvent from './services/externalEvent.factory';
import Broadcaster from './services/broadcaster.service';
import externalView from './services/externalView.service.js';
import webRtcAudio from './services/webRtcAudio.factory.js';
import incomingCall from './services/incomingCall.factory';
import callContext from './services/callContext.factory';
import keyboard from './services/keyboard.factory';
import electronWrapper from './services/electronWrapper.factory';
import volumeMeter from './directives/volumeMeter.directive';
import phoneAppToggler from './directives/phoneAppToggler.directive';
import processVolume from './services/processVolume.factory';
import audioQualityStatistics from './services/audioQualityStatistics.factory';
import focus from './services/focus.factory';
import dragDrop from './services/dragDrop.factory';
import fileReader from './services/fileReader.factory';
import onHold from './services/onHold.factory';
import onHoldNotifier from './services/onHoldNotifier.factory';
import mediaDevices from './services/mediaDevices.factory';
import preventCache from './services/preventCache.factory';
import errorModal from './services/errorModal.factory';
import JitsiProxy from './services/jitsiProxy.service';
import xcHelperPreferences from './services/xcHelperPreferences.factory';
import hour from './filters/hour.filter.js';
import trusted from './filters/trusted.filter.js';
import callDuration from './filters/callDuration.filter';
import userFriendlyDate from './filters/toUserFriendlyDate.filter';
import fromMap from './filters/fromMap.filter';
import IncomingCallPopup from './controllers/incomingCallPopup.controller';
import OnHoldPopup from './controllers/onHoldPopup.controller';
import KeyboardPopup from './controllers/keyboardPopup.controller';
import PinCodeModal from './controllers/pinCodeModal.controller';
import ExternalViewController from './controllers/externalView.controller';
import windowResize from './controllers/windowResize.controller';
import keyNavUtility from './services/keyNavUtility.service';
import volumeBar from './directives/volumeBar.directive';
import audioStatsDisplayer from './directives/audioStatsDisplayer.directive';
import xcLogger from './providers/xcLogger.provider';
import callControlActions from './services/callControlActions.factory';
import MeetingroomsInviteManager from './services/meetingroomsInviteManager.service';
import MobileAppCompatibilityService from './services/mobileAppCompatibility.service';
import MeetingroomsInviteDisplayer from './directives/meetingroomsInviteDisplayer.directive';
import NotificationModalController from "xchelper/controllers/notificationModal.controller";
import UserPreferenceService from "xchelper/services/userPreference.service";
import BrowserService from "xchelper/services/browser.service";
import AlertBrowserCompatibilityController from "xchelper/controllers/alertBrowserCompatibility.controller";
import {CallHistoryService} from "ucassistant/services/CallHistory.service";
import {BadgeManagerService} from "xchelper/services/badgeManager.service";

angular.module('xcHelper', [uirouter, uibootstrap, angulartranslate, ngclipboard, uiselect, localStorageService, scroll, ngcookies])
  .config(config)
  .config(['$provide', xcLogger])
  .directive('countBadge', countBadge)
  .directive('dateInput', dateInput)
  .directive('longText', longText)
  .directive('fixedHeader', fixedHeader)
  .directive('validPhoneNumber', validPhoneNumber)
  .directive('elapsedTimeFrom', elapsedTimeFrom)
  .directive('scrollText', scrollText)
  .directive('callActionDropdown', callActionDropdown)
  .directive('indeterminate', indeterminate)
  .directive('fileInput', fileInput)
  .directive('donutChart', donutChart)
  .directive('barChart', barChart)
  .directive('audioConfiguration', audioConfiguration)
  .directive('ringtoneConfiguration', ringtoneConfiguration)
  .directive('failedDestination', failedDestination)
  .directive('isPhoneNumber', isPhoneNumber)
  .directive('keyNavigation', keyNavigation)
  .directive('containerResizable', containerResizable)
  .directive('refreshWarning', refreshWarning)
  .directive('callLister', callLister)
  .directive('volumeMeter', volumeMeter)
  .directive('volumeBar', volumeBar)
  .directive('audioStatsDisplayer', audioStatsDisplayer)
  .directive('jitsiVideo', jitsiVideo)
  .directive('phoneAppToggler', phoneAppToggler)
  .directive('meetingroomsInviteDisplayer', MeetingroomsInviteDisplayer)
  .filter('hour', hour)
  .filter('trusted', trusted)
  .filter('callDuration', callDuration)
  .filter('toUserFriendlyDate', userFriendlyDate)
  .filter('fromMap', fromMap)
  .service('debounce', debounce)
  .service('ExternalEvent', ExternalEvent)
  .service('Broadcaster', Broadcaster)
  .service('externalView', externalView)
  .service('webRtcAudio', webRtcAudio)
  .service('callContext', callContext)
  .service('incomingCall', incomingCall)
  .service('errorModal', errorModal)
  .service('keyboard', keyboard)
  .service('mediaDevices', mediaDevices)
  .service('callHistoryPartyFormatter', callHistoryPartyFormatter)
  .service('CallHistoryService', CallHistoryService)
  .service('plantronics', plantronics)
  .service('headset', headset)
  .service('processVolume', processVolume)
  .service('JitsiProxy', JitsiProxy)
  .service('MeetingroomsInviteManager', MeetingroomsInviteManager)
  .service('MobileAppCompatibilityService', MobileAppCompatibilityService)
  .factory('keyNavUtility', keyNavUtility)
  .factory('electronWrapper', electronWrapper)
  .factory('focus', focus)
  .factory('dragDrop', dragDrop)
  .factory('fileReader', fileReader)
  .factory('onHold', onHold)
  .factory('onHoldNotifier', onHoldNotifier)
  .factory('preventCache', preventCache)
  .factory('xcHelperPreferences', xcHelperPreferences)
  .factory('audioQualityStatistics', audioQualityStatistics)
  .factory('callControlActions', callControlActions)
  .controller('ExternalViewController', ExternalViewController)
  .controller('windowResize', windowResize)
  .controller('IncomingCallPopup', IncomingCallPopup)
  .controller('OnHoldPopup', OnHoldPopup)
  .controller('KeyboardPopup', KeyboardPopup)
  .controller('PinCodeModal', PinCodeModal)
  .controller('NotificationModalController', NotificationModalController)
  .controller('AlertBrowserCompatibilityController', AlertBrowserCompatibilityController)
  .service('UserPreferenceService', UserPreferenceService)
  .service('BrowserService', BrowserService)
  .service('BadgeManagerService', BadgeManagerService)
  .run(function (UserPreferenceService, BadgeManagerService) {
    UserPreferenceService.init();
    BadgeManagerService.init();
  });

    

