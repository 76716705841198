export default function newMessageBadge(XucChat, $rootScope) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/newMessageBadge.html',
    scope: {
      primaryColor: '@'
    },
    link: (scope, elem) => {
      elem.css("background-color", scope.primaryColor);
      scope.hasMessage = false;

      function updateBadge(counter) {
        scope.hasMessage = counter > 0;
        if (!$rootScope.$$phase) scope.$apply(() => {
          scope.hasMessage;
        });       
      }

      scope.$on(XucChat.CHAT_UNREAD_MESSAGE, (event, counter) => {
        updateBadge(counter);
      });
      updateBadge(XucChat.getUnreadMessagesCount());
    }
  };
}
