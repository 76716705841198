export default function fileReader($q) {

  var onLoad = (reader, deferred, scope) => {
    return ()  => {
      scope.$apply( () => {
        deferred.resolve(reader.result);
      });
    };
  };

  var onError = function (reader, deferred, scope) {
    return () => {
      scope.$apply(() => {
        deferred.reject(reader.result);
      });
    };
  };

  var getReader = (deferred, scope) => {
    let reader = new FileReader();
    reader.onload = onLoad(reader, deferred, scope);
    reader.onerror = onError(reader, deferred, scope);
    return reader;
  };

  var _readAsText = (file, scope) => {
    let deferred = $q.defer();

    let reader = getReader(deferred, scope);
    reader.readAsText(file);

    return deferred.promise;
  };

  return {
    readAsText: _readAsText
  };
}