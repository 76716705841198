export default function preventCache($window) {
  const patterns = ["ccagent", "ccmanager", "ucassistant", "pratix", "xccti", "xchelper", "xclogin", "xcchat"];
  const suffixes = [".html", ".json"];

  var validPattern = (data) => {
    return patterns.some(p => data.includes(p)) &&
           suffixes.some(s => data.endsWith(s));
  };

  return {
    request: (config) => {
      if (validPattern(config.url) && $window.appVersion) {
        config.url += '?v=' + $window.appVersion;
      }
      return config;
    }
  };
}
