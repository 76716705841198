"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class XucVideoEventManager {
    constructor($log, $rootScope, XucLink, $window) {
        this.$log = $log;
        this.$rootScope = $rootScope;
        this.XucLink = XucLink;
        this.$window = $window;
        this.baseEventName = 'XucVideoEventManager';
        this.EVENT_VIDEO_START = "videoStart";
        this.EVENT_VIDEO_END = "videoEnd";
        this.EVENT_USER_BUSY = "Busy";
        this.EVENT_USER_AVAILABLE = "Available";
        this.EVENT_INVITE = "MeetingRoomInvite";
        this.EVENT_INVITE_ACK = "MeetingRoomInviteAck";
        this.EVENT_INVITE_RESPONSE = "MeetingRoomInviteResponse";
        this.onVideoInviteEvent = (inviteEvent) => {
            this.$rootScope.$emit(this.baseEventName + this.EVENT_INVITE, inviteEvent);
        };
        this.onVideoEvent = (videoEvent) => {
            this.$rootScope.$emit(this.baseEventName + videoEvent.status, videoEvent);
        };
        this.subscribe = (scope, callback, targetEvent) => {
            let unregister = this.$rootScope.$on(this.baseEventName + targetEvent, (_, event) => {
                this.$rootScope.$applyAsync(() => {
                    callback(event);
                });
            });
            scope.$on('$destroy', unregister);
        };
        this.init = () => {
            this.$log.info("Starting XucVideoEventManager service");
            this.$window.Cti.setHandler(this.$window.Cti.MessageType.VIDEOEVENT, this.onVideoEvent);
            this.$window.Cti.setHandler(this.$window.Cti.MessageType.MEETINGROOMINVITE, this.onVideoInviteEvent);
            this.XucLink.whenLoggedOut().then(this.unInit);
        };
        this.unInit = () => {
            this.$log.info("Unloading XucVideoEventManager service");
            this.XucLink.whenLogged().then(this.init);
        };
        this.subscribeToVideoStatusEvent = (scope, callback) => {
            this.subscribe(scope, callback, this.EVENT_USER_BUSY);
            this.subscribe(scope, callback, this.EVENT_USER_AVAILABLE);
        };
        this.subscribeToVideoInviteEvent = (scope, callback) => {
            this.subscribe(scope, callback, this.EVENT_INVITE);
        };
        this.XucLink.whenLogged().then(this.init);
    }
}
exports.default = XucVideoEventManager;
XucVideoEventManager.$inject = ["$log", "$rootScope", "XucLink", "$window"];
