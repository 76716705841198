export default function callRemoteParty(callContext, JitsiProxy) {
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xcchat/directives/callRemoteParty.html',
    scope: {
      remoteParty: '@',
      userDisplayName: '@'
    },
    link: (scope) => {

      scope.callRemoteParty = () => {
        callContext.dialByUsername(scope.remoteParty);
      };

      scope.startPointToPointVideoCall = () => {
        JitsiProxy.startPointToPointVideoCall(scope.remoteParty, scope.userDisplayName);
      };

      scope.isJitsiAvailable = () => {
        return JitsiProxy.jitsiAvailable;
      };
    }
  };
}
