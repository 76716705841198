(function() {
  'use strict';

  angular.module('xcLogin').factory('userRights', ['XucLink', '$log', UserRights]);
  
  function UserRights(XucLink, $log) {
    var ADMIN_ROLE_NAME = 'Admin';
    var SUPERVISOR_ROLE_NAME = 'Supervisor';

    var _allowCCManager= function(profile) {
      return (profile === ADMIN_ROLE_NAME || profile === SUPERVISOR_ROLE_NAME);
    };

    var getDissuasionRight = function() {
      return XucLink.getProfileRightAsync().then(function(rightProfile) {
        if (rightProfile.profile === 'Supervisor') {
          return rightProfile.rights.includes('dissuasion');
        } else if (rightProfile.profile === 'Admin') {
          return true;
        } else {
          return false;
        }        
      }, (function (error) {
        $log.error(error);
        return false;
      }));   
    };      

    return {
      allowCCManager: _allowCCManager,
      getDissuasionRight: getDissuasionRight
    };
  }
  
})();

