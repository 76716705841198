export default class MenuController {

  constructor($scope, $state, $uibModal, $uibModalStack, $document, $timeout, $log, personalContact, CtiProxy, XucLink, $window, JitsiProxy) {
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$uibModalStack = $uibModalStack;
    this.$document = $document;
    this.$timeout = $timeout;
    this.stopTimeout;
    this.$log = $log;
    this.personalContact = personalContact;
    this.ctiProxy = CtiProxy;
    this.xucLink = XucLink;
    this.$window = $window;
    this.jitsiProxy = JitsiProxy;

    this.$scope.$watch('importFile', this.import.bind(this));
  }

  isWebRtc() {
    return this.ctiProxy.isUsingWebRtc();
  }

  close(dest) {
    this.$state.go(dest);
    this.$uibModalStack.dismissAll();
  }

  remove() {
    this.personalContact.removeAll().then(() => {
      this.close('interface.favorites');
    });
  }

  uploadFile() {
    this.stopTimeout = this.$timeout(() => { // force ongoing apply to be sequenced
      this.$document.find("#import-contacts").click();
      this.$scope.importFile = false; // set a transient value to trigger $watch
    }, 0);
  }

  import(newFile, oldFile) {
    this.$timeout.cancel(this.stopTimeout);
    if (newFile === oldFile || !newFile) return;
    this.$scope.ajaxRequest = {
      ongoing: true,
      error: false
    };
    this.personalContact.import(newFile, this.$scope).then((response) => {
      this.$scope.ajaxRequest.success = true;
      this.$scope.imported = response.data;
    },(response) => {
      if (response.data.error) {
        this.$scope.ajaxRequest['error'+response.data.error] = true;
      }
      else {
        this.$scope.ajaxRequest.error = true;
      }
    }).finally(() => {
      this.$scope.ajaxRequest.ongoing = false;
      delete this.$scope.importFile;
    });
  }

  export() {
    this.personalContact.export();
  }

  confirmDelete() {
    this.$uibModal.open({
      templateUrl: 'confirmDialog.html',
      size: 'sm',
      scope: this.$scope,
      controller: 'ConfirmationModalController',
      controllerAs: 'modalCtrl',
      resolve: {
        params: () => {
          return {deleteAll: true, item: 'CONTACT'};
        }
      }
    });
  }

  logout() {
    if (this.$window.externalConfig.useSso) this.xucLink.clearCredentials();
    if (this.jitsiProxy.videoIsOngoing()) this.jitsiProxy.dismissVideo();
    this.$uibModalStack.dismissAll();
    this.xucLink.logout();
    this.ctiProxy.stopUsingWebRtc();
    this.$state.go("login", {
      error: "Logout"
    });
  }

  isJitsiAvailable() {
    return this.jitsiProxy.jitsiAvailable;
  }
}