export default function xcHelperPreferences(localStorageService) {

  var _setRingingDeviceId = (id) => {
    localStorageService.set('ringingDeviceId', id);
  };

  var _getRingingDeviceId = () => {
    return localStorageService.get('ringingDeviceId') || 'default';
  };

  return {
    setRingingDeviceId: _setRingingDeviceId,
    getRingingDeviceId: _getRingingDeviceId
  };
}