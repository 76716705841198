export default function audioConfiguration(mediaDevices, webRtcAudio, xcHelperPreferences) {

  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/audioConfiguration.html',
    controller: ($scope) => {
      
      $scope.devices = [];
      
      function _getOutputDevices () {
        mediaDevices.getAudioOutput().then((d) => {
          $scope.devices = d;
        });
      }

      _getOutputDevices();

      $scope.ringingDeviceId = xcHelperPreferences.getRingingDeviceId();

      $scope.changeRingingDevice = ($model) => {
        webRtcAudio.changeRingingDevice($model).then(() => {
          xcHelperPreferences.setRingingDeviceId($model);
        }, () => {
          angular.noop();
        });
      };
    }
  };
}
