export default class windowResize {

  constructor($scope) {
    $scope.messageResize = true;

    $scope.dismissMessage = () => {
      $scope.messageResize = false;
    };
  }
}
