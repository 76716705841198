import moment from 'moment';
export default function callDuration() {
  return function (input) {

    if (!input) return "";

    let inputArray = input.split(':');
    if (inputArray[0] == '00') {
      let newFormat = moment(input, 'hh:mm:ss').format('mm:ss');
      return newFormat;
    } else {
      return input;
    }

  };
}