export default function keyNavigation(keyNavUtility) {

  return {
    restrict: 'A',
    scope: {
      knDivCount: '@',
      knAction: '&',
      knElemId: '@',
      knKeybindings: '='
    },

    link: function (scope) {

      const onKeyDown = (event) => {
        if (scope.knKeybindings.includes(event.key) && !keyNavUtility.isStopped()) {
          event.preventDefault();
          switch(event.key) {
          case 'PageUp':
          case 'ArrowUp':
            keyNavUtility.move('up', scope.knDivCount, scope.knElemId);
            break;
          case 'PageDown':
          case 'ArrowDown':
            keyNavUtility.move('down', scope.knDivCount, scope.knElemId);
            break;
          case 'Enter':
            if(document.activeElement.id.includes(scope.knElemId)) {
              keyNavUtility.action(scope.knAction);
            }
            break;
          }
        }
      };

      document.addEventListener("keydown", onKeyDown);
      scope.$on('$destroy', function() {
        document.removeEventListener("keydown", onKeyDown);
      });
    }
  };
}