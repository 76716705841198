import _ from 'lodash';

export default function XucUtils() {

  var _normalize = function(number) {
    if (typeof(number) !== "undefined") {
      var trimmed = number.replace(/[ ]/g,"");
      if(_isASipUri(trimmed))
        return trimmed;
      if(trimmed.charAt(0) == '*') {
        return /^\**/.exec(trimmed) + trimmed.replace(/[+().]/g,"").replace(/^\*\**(?=\d?)/g,"");
      } else {
        return trimmed.replace(/^\+/,"00").replace(/[+().]/g,"").replace(/^\*\**(?=\d?)/g,"");
      }
    }
    else
      return "";
  };

  const _sipUriRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const _phoneRegex = /^\**\d+\*{0,2}\d*$/;

  var _reshapeInput = function (str) {
    const mystrCopy = _normalize(str);

    if (_isaPhoneNb(mystrCopy)) {
      return { type: 'phone', value: mystrCopy };
    } else {
      return { type: 'search', value: str };
    }
  };

  var _isASipUri = function(value) {
    return _sipUriRegex.test(value);
  };

  var _isaPhoneNb = function(value) {
    return _phoneRegex.test(value) || _sipUriRegex.test(value);
  };

  var _prettyPhoneNb = function(number) {
    if(number.length < 6 || _sipUriRegex.test(number) || number.charAt(0) == '+') {
      return number;      
    }
    

    var modulo, offset = 1;
    if(number.length % 2 === 0) {
      modulo = 2;
    } else if(number.length % 3 === 0) {
      modulo = 3;
    } else {
      offset = 0;
      modulo = 2;
    }

    return _.trim(
      _.join(
        _.map(_.toArray(number), function(value, index) {
          return value + ((index+offset) % modulo === 0?' ':'');
        }), ''
      )
    );
  };

  return {
    reshapeInput : _reshapeInput,
    normalizePhoneNb : _normalize,
    isaPhoneNb : _isaPhoneNb,
    prettyPhoneNb: _prettyPhoneNb
  };
}
