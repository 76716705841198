"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class VoicemailController {
    constructor($scope, XucVoiceMail, CtiProxy, XucPhoneState, $rootScope) {
        $scope.voiceMail = XucVoiceMail.getVoiceMail();
        $scope.$on('voicemailUpdated', function () {
            $scope.voiceMail = XucVoiceMail.getVoiceMail();
            if (!$rootScope.$$phase)
                $scope.$apply();
        });
        $scope.callMailBox = function () {
            if (XucPhoneState.getCalls().length > 0)
                CtiProxy.hold();
            $rootScope.$broadcast("EventShowCallPlaceHolder");
            CtiProxy.dial('*98');
        };
    }
}
exports.default = VoicemailController;
