export default class InitController {

  constructor($scope, $rootScope, $state, $window, $log, $timeout, $translate, errorModal, XucPhoneState, CtiProxy, callContext, XucLink, XucSheet, ExternalEvent, electronWrapper, plantronics, headset, XucNotification, JitsiProxy) {

    this.XucSheet = XucSheet;
    this.plantronics = plantronics;
    this.headset = headset;
    this.jitsiProxy = JitsiProxy;

    CtiProxy.disableMediaKeys();
    

    $scope.getPhoneStateBackColor = (contact) => {
      let lighter = contact.keydown ? " lighter" : "";
      if (contact.videoStatus == "Busy") return "user-videostatus-" + contact.videoStatus;
      return "user-status" + contact.status + lighter;
    };

    $scope.getPhoneStateLabel = (state, videoState) => {
      if (videoState == 'Busy') return "USER_VIDEOSTATUS_" + videoState;
      return "USER_STATUS_" + state;
    };

    XucNotification.enableCallNotification();
    XucNotification.enableChatNotification();
    
    ExternalEvent.registerConfirmQuitCallback(() => {
      CtiProxy.hangup();
      CtiProxy.stopUsingWebRtc();
      electronWrapper.forceQuit();
    });

    this.jitsiProxy.subscribeToCloseVideo($scope, () => {
      if ($scope.phoneBarIsVisible() == false) {
        this.jitsiProxy.togglePhoneBar();
      }
      if (!$rootScope.$$phase) $scope.$apply();
    });
    
    $scope.phoneBarIsVisible = () => {
      return this.jitsiProxy.getShowPhoneBar();
    };
    
    $scope.$on('linkDisConnected', function () {
      $log.warn('InitController - Received linkDisConnected');
      if (CtiProxy.isUsingWebRtc() && XucPhoneState.getCalls().length > 0) {
        $log.warn('CTI WS closed when CtiProxy is using WebRtc');
        errorModal.showErrorModal('CTI_SOCKET_CLOSED_WHILE_USING_WEBRTC');
      } else {
        if (CtiProxy.isUsingWebRtc()) {
          CtiProxy.stopUsingWebRtc();
        }
        XucLink.logout();
        $state.go('login', { 'error': 'LinkClosed' });
      }
    });
  }
}
